import {
  ArchiveBoxIcon,
  ArrowDownIcon,
  ArrowsPointingOutIcon,
  BellSnoozeIcon,
  BoltIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  DocumentCheckIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  FolderArrowDownIcon,
  FolderMinusIcon,
  HandRaisedIcon,
  HandThumbUpIcon,
  HeartIcon,
  IdentificationIcon,
  InboxArrowDownIcon,
  InformationCircleIcon,
  LightBulbIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  MinusCircleIcon,
  MinusIcon,
  NoSymbolIcon,
  PaperClipIcon,
  PauseCircleIcon,
  PencilIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  PhoneIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  QueueListIcon,
  RssIcon,
  ShieldCheckIcon,
  SignalIcon,
  SignalSlashIcon,
  Square2StackIcon,
  StarIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
  UserPlusIcon,
  UsersIcon,
  WindowIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArchiveBoxIcon as ArchiveBoxIconMini,
  CheckCircleIcon as CheckCircleIconMini,
  ClockIcon as ClockIconMini,
  DocumentCheckIcon as DocumentCheckIconMini,
  ExclamationCircleIcon as ExclamationCircleIconMini,
  ExclamationTriangleIcon as ExclamationTriangleIconMini,
  FolderArrowDownIcon as FolderArrowDownMini,
  FolderMinusIcon as FolderMinusIconMini,
  HandRaisedIcon as HandRaisedIconMini,
  InboxArrowDownIcon as InboxArrowDownIconMini,
  InformationCircleIcon as InformationCircleIconMini,
  MinusCircleIcon as MinusCircleIconMini,
  PauseCircleIcon as PauseCircleIconMini,
  PhoneArrowDownLeftIcon as PhoneArrowDownLeftIconMini,
  QueueListIcon as QueueListIconMini,
  RssIcon as RssIconMini,
  ShieldCheckIcon as ShieldCheckIconMini,
  SignalSlashIcon as SignalSlashIconMini,
  UserPlusIcon as UserPlusIconMini,
} from '@heroicons/react/20/solid';
import { tv } from '../../utils';

// We use two different sets of icons in the Vanna design system: Heroicons outlined and Heroicons
// mini. The general rule of thumb is to use the outlined variant, however sometimes the mini is
// needed by design. Because of this, icon names in this map should be a 1:1 match with the
// original name if they're a part of the outlined set, and should be suffixed with -mini if the
// icon is from the mini set. All icons should be snake-case
const icons = {
  'archive-box': ArchiveBoxIcon,
  'arrow-down': ArrowDownIcon,
  'arrows-pointing-out': ArrowsPointingOutIcon,
  'bell-snooze': BellSnoozeIcon,
  bolt: BoltIcon,
  calendar: CalendarIcon,
  'chat-bubble-left-right': ChatBubbleLeftRightIcon,
  'check-badge': CheckBadgeIcon,
  'check-circle': CheckCircleIcon,
  check: CheckIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  clipboard: ClipboardIcon,
  'clipboard-document': ClipboardDocumentIcon,
  clock: ClockIcon,
  copy: Square2StackIcon,
  'device-phone-mobile': DevicePhoneMobileIcon,
  'document-check': DocumentCheckIcon,
  'ellipsis-vertical': EllipsisVerticalIcon,
  envelope: EnvelopeIcon,
  'exclamation-circle': ExclamationCircleIcon,
  'exclamation-triangle': ExclamationTriangleIcon,
  'folder-arrow-down': FolderArrowDownIcon,
  'folder-minus': FolderMinusIcon,
  'hand-raised': HandRaisedIcon,
  'hand-thumbs-up': HandThumbUpIcon,
  heart: HeartIcon,
  identification: IdentificationIcon,
  'inbox-arrow-down': InboxArrowDownIcon,
  'information-circle': InformationCircleIcon,
  lightbulb: LightBulbIcon,
  'magnifying-glass': MagnifyingGlassIcon,
  'map-pin': MapPinIcon,
  'minus-circle': MinusCircleIcon,
  minus: MinusIcon,
  'no-symbol': NoSymbolIcon,
  'paper-clip': PaperClipIcon,
  'pause-circle': PauseCircleIcon,
  pencil: PencilIcon,
  phone: PhoneIcon,
  'phone-arrow-down-left': PhoneArrowDownLeftIcon,
  'phone-arrow-up-right': PhoneArrowUpRightIcon,
  plus: PlusIcon,
  'question-mark-circle': QuestionMarkCircleIcon,
  'queue-list': QueueListIcon,
  rss: RssIcon,
  'shield-check': ShieldCheckIcon,
  signal: SignalIcon,
  'signal-slash': SignalSlashIcon,
  star: StarIcon,
  'user-group': UserGroupIcon,
  'user-plus': UserPlusIcon,
  trash: TrashIcon,
  user: UserIcon,
  users: UsersIcon,
  window: WindowIcon,
  'x-mark': XMarkIcon,

  // Mini Icons
  'archive-box-mini': ArchiveBoxIconMini,
  'check-circle-mini': CheckCircleIconMini,
  'clock-mini': ClockIconMini,
  'document-check-mini': DocumentCheckIconMini,
  'exclamation-circle-mini': ExclamationCircleIconMini,
  'exclamation-triangle-mini': ExclamationTriangleIconMini,
  'folder-arrow-down-mini': FolderArrowDownMini,
  'folder-minus-mini': FolderMinusIconMini,
  'hand-raised-mini': HandRaisedIconMini,
  'inbox-arrow-down-mini': InboxArrowDownIconMini,
  'information-circle-mini': InformationCircleIconMini,
  'minus-circle-mini': MinusCircleIconMini,
  'pause-circle-mini': PauseCircleIconMini,
  'phone-arrow-down-left-mini': PhoneArrowDownLeftIconMini,
  'rss-mini': RssIconMini,
  'shield-check-mini': ShieldCheckIconMini,
  'signal-slash-mini': SignalSlashIconMini,
  'queue-list-mini': QueueListIconMini,
  'user-plus-mini': UserPlusIconMini,
};

export type IconName = keyof typeof icons;

export interface IconProps {
  /** Adds additional Tailwind classes to the Icon container element */
  className?: string;
  /** Optionally includes a label for screen readers (will hide the icon if omitted) */
  label?: string;
  /** The name of the Icon to render */
  name: IconName;
}

const iconStyles = tv({
  // TODO: We may want to include a size prop in the future if StoneTable wants to have multiple
  // preset icon sizes, but for now we're defaulting to 16px and letting engineers override with
  // the className prop
  slots: {
    base: 'h-auto inline-block w-4',
    icon: 'h-full w-full',
  },
});

/**
 * Renders a single Icon from the vanna Connect design system
 *
 * @example
 * ```tsx
 * <Icon name="plus" />
 * ```
 */
export function Icon({ className, label, name }: IconProps) {
  const styles = iconStyles();
  const SelectedIcon = icons[name];
  return (
    <span className={styles.base({ className })}>
      {label && <span className="sr-only">{label}</span>}
      <SelectedIcon
        aria-hidden={!label}
        className={styles.icon()}
        data-testid={`icon-${name}`}
      />
    </span>
  );
}
