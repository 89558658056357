import { useQuery as useTanstackQuery } from '@tanstack/react-query';

import { requestHandler } from '#utils/tanstackQuery';
import type { QueryParams, RequestParams } from '#utils/tanstackQuery';

import type { UseQueryResult } from './useQuery.types';

/**
 * A hook used to create typesafe GET requests to an API
 * @param url The URL you wish to fetch
 * @param queryKey An array with a unique name for your query and optionally any additional params
 * @param reqParams Additional Fetch params to send with the request (optional)
 * @param queryParams Additional Tanstack Query params to initialize the hook with (optional)
 *
 * @example
 * ```tsx
 * const { data, error, isLoading } = useQuery(
 *   'http://todolist.private/tasks',
 *   ['tasks', { status: 'done' }],
 *   { headers: { Authorization: 'Bearer some-token' } },
 *   { enabled: false }
 * );
 */
export function useQuery<R extends object | object[]>(
  url: string,
  queryKey: [namespace: string, params?: object],
  reqParams: RequestParams = {},
  queryParams: QueryParams<R> = {},
) {
  const queryFn = () => requestHandler<R>(url, reqParams);
  const res = useTanstackQuery<R, Error>({
    ...queryParams,
    queryKey,
    queryFn,
  });

  const data = res.data || null;
  const processedData =
    queryParams.postProcessData && data
      ? queryParams.postProcessData(data)
      : data;

  return { ...res, data: processedData } as UseQueryResult<R>;
}
