import type { Bundle, Resource } from '@medplum/fhirtypes';

import type { VannaResource } from '../types';
import * as fhirpaths from './bundle.fhirpaths';

/**
 * Gets all of the bundle's resources and casts them to T
 * @typeParam T A FHIR resource type to cast the bundle's resources array to
 * @param bundle A FHIR Bundle resource
 * @returns A array of FHIR resources cast to `T`
 *
 * @example
 * ```typescript
 * import type { Patient } from '@medplum/fhirtypes';
 *
 * const resources = vannaBundle.entry.getResources<Patient>(fhirBundle);
 * ```
 */
function getResources<T extends Resource>(fhir: Bundle): T[] {
  return fhirpaths.getResources(fhir) as T[];
}

export type VannaBundle = VannaResource<Bundle>;

/**
 * Vanna FHIR Bundle Business Logic (Getter and Setter helpers)
 *
 * "Why mess with ridiculous, deeply nested objects when a function
 * can do it for you?" (TM)
 *
 * @example
 * ```ts
 * const resources = vannaBundle.entry.getResources<Patient>(fhirBundle);
 * ```
 */
export const vannaBundle = {
  entry: {
    getResources,
  },
} satisfies VannaBundle;
