import { parseFhirPath, toTypedValue } from '@medplum/core';
import type { Resource as FhirResource } from '../Resources/types';

type selectorFns = typeof firstResultSelector | typeof allResultsSelector;

/**
 * FHIRPath getter factory is used to create FHIRPath eval functions that automatically
 * run the results against a provided selector function.
 *
 * type T -- The FHIRPath result type (caller specified, not inferred from the results).
 *           Whether or not results are actually that type is the caller's responsibility.
 *
 * @param path - The FHIRPath string to query
 * @param selector - A selector function that processes FHIRPath result arrays
 * @returns getter function that takes a FHIR object and returns the results of the
 *          FHIRPath query, as determined by the given selector function.
 */
function constructGetter<T>(
  path: string,
  selector: selectorFns,
): (resource: FhirResource) => T {
  // Pre-compile the resource's FHIRPath getter
  const ast = parseFhirPath(path);

  return (resource: FhirResource) => {
    // Evaluate the passed in object and typecast it to the generic provided in the function. This
    // can be pretty unsafe, so we may want to figure out a more intelligent way to infer typings
    // with generics in the future
    const results = ast
      .eval({ variables: {} }, [toTypedValue(resource)])
      .map(({ value }) => value) as T[];
    return selector(results) as T;
  };
}

/**
 * Select the first FHIRPath results, if it exists
 *
 * @param results Given an array of FHIRPath results (T[])
 * @returns Return the first T or undefined, if results is empty
 */
function firstResultSelector<T>(results: T[]): T | undefined {
  if (results.length > 0) {
    return results[0];
  }
}

/**
 * Select all FHIRPath results
 *
 * @param results Given an array of FHIRPath results (T[])
 * @returns Return all the results
 */
function allResultsSelector<T>(results: T[]): T[] {
  return results;
}

/**
 * Given a FHIRPath string, return a compiled fhirpath.js getter that returns the first
 * result, if it exists. (Cardinality: 0..*)
 *
 * FHIRPath always returns an array of results, even if it's empty or has a single value.
 * FirstResult is a convenience function to safely unpack the first result in the array.
 *
 * type T -- The FHIRPath result type (caller specified, not inferred from the results).
 *           Whether or not results are actually that type are caller's responsibility.
 *
 * @param path A FHIRPath string
 *
 * @returns Getter function that returns the first result, if it exists
 *          (results: T[]) => T | undefined
 *
 * @example
 * ```typescript
 * const getOfficialName = constructFirstResultGetter<HumanName>(FhirPaths.officialName);
 * const officialName = getOfficialName(fhir);
 * // officialName is HumanName object or undefined
 * ```
 */
export function constructFirstResultGetter<T>(
  path: string,
): (resource: FhirResource) => T | undefined {
  return constructGetter<T | undefined>(path, firstResultSelector);
}

/**
 * Given a FHIRPath string, return a compiled fhirpath.js getter that returns the first
 * result, when existence is guaranteed. (Cardinality: 1..*)
 *
 * FHIRPath always returns an array of results, even if it's empty or has a single value.
 * FirstResult is a convenience function to safely unpack the first result in the array.
 *
 * type T -- The FHIRPath result type (caller specified, not inferred from the results).
 *           Whether or not results are actually that type are caller's responsibility.
 *
 * @param path A FHIRPath string
 *
 * @returns Getter function that returns the first result
 *          (results: T[]) => T
 *
 * @example
 * ```typescript
 * const getEnrollmentStage = constructFirstResultGetter<Code>(FhirPaths.enrollmentStage);
 * const enrollmentStage = getEnrollmentStage(fhir);
 * // enrollmentStage is a Code object
 * ```
 */
export function constructRequiredFirstResultGetter<T>(
  path: string,
): (resource: FhirResource) => T {
  return constructGetter<T>(path, firstResultSelector);
}

/**
 * Given a FHIRPath string, return a compiled fhirpath.js getter that returns all results.
 *
 * FHIRPath always returns an array of results, even if it's empty or has a single value.
 * AllResults returns results without changes.
 *
 * type T -- The FHIRPath result type (caller specified, not inferred from the results).
 *           Whether or not results are actually that type are caller's responsibility.
 *
 * @param path A FHIRPath string
 *
 * @returns Getter function that returns all results
 *          (results: T[]) => T[]
 *
 * @example
 * ```typescript
 * const getNicknames = constructAllResultsGetter<HumanName>(FhirPaths.nickname);
 * const nicknames = getNicknames(fhir); // nicknames is HumanName[]
 * ```
 */
export function constructAllResultsGetter<T>(
  path: string,
): (resource: FhirResource) => T[] {
  return constructGetter<T[]>(path, allResultsSelector);
}
