// population export ID for setting the default route/param-based query = 0
export const LATEST_POPULATION_ID = 100;

// member selection enum values
export const MEMBER_SELECTION_ENUMS = {
  SELECTION_STATUS: ['yes', 'no', 'maybe', 'not_reviewed'],
  SELECTION_NEXT_STEPS: [
    'review_additional_data',
    'speak_to_providers',
    'assess_during_initial_encounter',
    'other',
  ],
  OUTREACH_TIMELINE: ['now', 'later'],
  OUTREACH_TIMELINE_REASON: [
    'too_complex_for_current_team',
    'low_recent_costs_but_meets_diagnostic_criteria',
    'other',
  ],
  COHORT_ASSIGNMENT: ['control', 'not_assigned', 'treatment'],
};

// conditional color coding constants for column
export const MEMBER_SELECTION_MAX_VALUES = {
  ANNUAL__TOTAL_COST: 150000,
  QUARTERLY__TOTAL_COST: 25000,
  QUARTERLY__PCT_ENCOUNTER_COST_ED_IP: 1,
  QUARTERLY__PCT_PHARMACY_COST: 1,
  QUARTERLY__PHARMACY_COST: 5000,
  QUARTERLY__PCT_ENCOUNTER_PREVENTABLE_COST: 1,
  QUARTERLY__N_ENCOUNTERS__BH_IP: 8,
  QUARTERLY__N_ENCOUNTERS__BH_RES: 3,
  QUARTERLY__N_ENCOUNTERS__ED: 12,
  QUARTERLY__N_ENCOUNTERS__MED_IP: 6,
  QUARTERLY__N_ENCOUNTERS__PCP: 6,
  QUARTERLY__MOST_EXPENSIVE_ENCOUNTER_ED_IP__PAID_AMOUNT: 30000,
  QUARTERLY__MOST_EXPENSIVE_PREVENTABLE_ENCOUNTER_ED_IP__PAID_AMOUNT: 30000,
  QUARTERLY__MOST_COMMON_ENCOUNTER_DIAGNOSIS_ED_IP__COUNT: 12,
  ANNUAL__MOST_COMMON_ENCOUNTER_DIAGNOSIS_ED_IP__COUNT: 24,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__ANTIDEPRESSANT: 90,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__ANTIPSYCHOTIC: 90,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__BLOOD_PRESSURE: 90,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__CHF: 90,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__DIABETES: 90,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__LONG_ACTING_INJECTABLE: 90,
  QUARTERLY__N_MEDICATION_COVERED_DAYS__MOOD_STABILIZER: 90,
};
