import { useQueryClient } from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery } from '@vannaconnect/ui';
import { useEffect } from 'react';

import { AUTH_TOKENS_ENDPOINT } from '../../constants/auth';
import {
  isTokenExpired,
  logOut,
  retrieveTokensFromStorage,
  setTokensInStorage,
} from '../../utils/auth';
import type { MedplumTokens } from '../../utils/auth';

import { useMedplumClient } from '../useMedplumClient';

/** Partial success response from Medplum's /oauth2/token endpoint */
export interface MedplumAuthTokensResponse {
  access_token: string;
  refresh_token: string;
}

/**
 * Parses a given URL and gets the auth code from the URL's query string if provided
 * @param url The URL to parse
 * @returns an auth code if found in the query string, otherwise returns null
 */
export function getAuthCodeFromUrl(url: string): string | null {
  const queryParams = new URLSearchParams(url.split('?')?.[1] ?? '');
  return queryParams.get('code');
}

/**
 * Uses a combination of local storage, URL params, and JWT parsing to construct a form body to
 * send to  Medplum's /oauth2/token endpoint
 * @returns a stringified URL encoded form body or null if no valid body can be created
 */
export function getQueryRequestBody(
  authCode: string | null,
  storedTokens: MedplumTokens | null,
  queryClient: QueryClient,
  medplumClientId: string,
): string | null {
  const formBody = new URLSearchParams();

  // If a "code" parameter is found in the query string, this means (in theory) that the user was
  // redirected to our app from an external identity provider (such as Okta). If this parameter
  // exists, we're going to make the assumption that we should fetch new Medplum tokens regardless
  // of the validity of our existing tokens
  if (authCode) {
    formBody.set('grant_type', 'authorization_code');
    formBody.set('code', authCode);
    return formBody.toString();
  }

  // If no code parameter is found, we can assume that we're either trying to refresh an expired
  // access token or log the user in for the first time. To determine if we should be attempting
  // to refresh or not, we'll first need to get the refresh token and determine its validity

  // The refresh token can come from two different places:
  // 1. The user's local storage (only pulled from here on initial app load)
  // 2. The Tanstack Query cache
  // We'll attempt to grab it from both, prioritizing values from the Tanstack Query cache
  const refreshTokenQuery = queryClient
    .getQueryCache()
    .find<MedplumAuthTokensResponse>({ queryKey: [AUTH_TOKENS_ENDPOINT] })
    ?.state?.data?.refresh_token;
  const refreshTokenLocalStorage = storedTokens?.refreshToken;
  const refreshToken = refreshTokenQuery || refreshTokenLocalStorage;
  if (refreshToken) {
    formBody.set('grant_type', 'refresh_token');
    formBody.set('client_id', medplumClientId);
    formBody.set('refresh_token', refreshToken);
    return formBody.toString();
  }

  // Can no valid body be created? This means the user is likely either logging in for the first
  // time or has been inactive long enough that their refresh token has expired. We'll return null
  // here and assume the hook will understand not to execute the request when this is returned
  return null;
}

/**
 * A hook that fetches Medplum access and refresh tokens from local storage, validates them, and
 * stores them in Tanstack Query's cache, refetching them if invalid
 * @returns an object containing a Medplum access token, a loading state, and a refetch function
 *
 * @example
 * ```
 * const { accessToken, refreshToken } = useAuthToken();
 * ```
 */
export function useAuthToken() {
  const { baseUrl, clientId } = useMedplumClient();
  const queryClient = useQueryClient();
  const storedTokens = retrieveTokensFromStorage();

  const authCode = getAuthCodeFromUrl(window.location.href);
  const isAccessTokenValid =
    !!storedTokens && !isTokenExpired(storedTokens.accessToken);
  const isRefreshTokenValid =
    !!storedTokens && !isTokenExpired(storedTokens.refreshToken);
  const isStoredDataValid = isAccessTokenValid && isRefreshTokenValid;

  // If no request body is generated, we'll skip the request (see the enabled param in useQuery)
  const potentialRequestBody = getQueryRequestBody(
    authCode,
    storedTokens,
    queryClient,
    clientId,
  );

  // If we can't compute a body or our refresh token is invalid, don't call the endpoint
  const isQueryEnabled =
    !!potentialRequestBody && (isRefreshTokenValid || !!authCode);

  // Although this hook utilizes both Tanstack Query and a Medplum API call, it's technically an
  // OAuth2 resource, not a FHIR resource. Because of this, we're breaking our usual patterns with
  // this hook and not using the useFhirResource hook to fetch this data since there's no benefit
  // to doing so. This is an abnormal pattern and should not be repeated outside of auth
  const res = useQuery<MedplumAuthTokensResponse>(
    `${baseUrl}/${AUTH_TOKENS_ENDPOINT}`,
    [AUTH_TOKENS_ENDPOINT],
    {
      body: potentialRequestBody!,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'post',
    },
    // Assuming our locally stored tokens are valid, we'll set the query's initial value, otherwise
    // we'll fetch. When fetching, we'll disable automatic retries since since this is an auth-only
    // call and not something we'd usually want to retry. This breaks pattern and we have to invoke
    // a ts-expect-error in order to disable retries since this breaks pattern
    {
      enabled: isQueryEnabled,
      initialData: isStoredDataValid
        ? {
            access_token: storedTokens.accessToken,
            refresh_token: storedTokens.refreshToken,
          }
        : undefined,
      initialDataUpdatedAt: isStoredDataValid ? Date.now() : undefined,
      retry: 0,
      // Medplum tokens last an hour, so to be on the safe side we'll set our token to refresh
      // every 45 minutes (that's 2,700,000 ms)
      refetchInterval: 2700000,
    },
  );

  /**
   * Exchanges a saved refresh token for a new access token
   */
  const refreshAccessToken = async () => {
    await res.refetch();
  };

  // If we get new tokens and they're different than our stored tokens (or we don't have any stored
  // tokens), let's update local storage
  useEffect(() => {
    if (
      res.data &&
      (storedTokens?.accessToken !== res.data.access_token ||
        storedTokens?.refreshToken !== res.data?.refresh_token)
    ) {
      setTokensInStorage({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });

      // If an auth code is found in the URL, we'll also redirect the user so that the app doesn't
      // get stuck in an infinite auth fetching loop. We have to do this by directly modifying the
      // window location since auth exists outside of the context of React Router
      if (authCode) {
        window.location.href = '/';
      }
    }
  }, [authCode, res.data, storedTokens]);

  // If our hook encounters an error, let's log the user out and clear any stored tokens. This
  // usually happens when both of the user's tokens have expired due to inactivity, so we'll give
  // them a friendly error message
  useEffect(() => {
    if (res.error) {
      console.error(res.error);

      // Delaying logout by 500ms to make sure sentry can log errors
      setTimeout(() => {
        logOut("You've been logged out due to inactivity, please log in again");
      }, 500);
    }
  }, [res.error]);

  return {
    accessToken: res.data?.access_token ?? null,
    isLoading: res.isLoading,
    refreshAccessToken,
  };
}
