import type { ReactNode } from 'react';
import { Menu as ReactAriaMenu } from 'react-aria-components';

import { tv } from '../../utils';
import { MenuItem } from './MenuItem';
import { MenuSection } from './MenuSection';

export interface MenuProps {
  /** The Menu items */
  children: ReactNode;
  /** Adds additional Tailwind classes to the Menu container element */
  className?: string;
  /** An accessible label for the Menu. If Menu is contained in a popover, it inherits its label */
  label?: string;
}

const menuStyles = tv({
  slots: {
    base: 'flex flex-col w-auto',
    menuDivider: 'block p-1 bg-neutrals-1 text-neutrals-8',
  },
});

/**
 * Displays a list of options with click events for each
 *
 * @example
 * ```tsx
 * <Menu>
 *   <Menu.Item onPress={onItem1Click}>Item 1</Menu.Item>
 *   <Menu.Item avatar={{}} description="Item description" onPress={onItem2Click}>
 *     Item 2
 *   </Menu.Item>
 *   <Menu.Item isDisabled onPress={onItem3Click}>
 *     Item 3
 *   </Menu.Item>
 *   <Menu.Section title="Sub Section">
 *     <Menu.Item onPress={onSubItemClick}>Sub Item</Menu.Item>
 *   </Menu.Section>
 * ```
 */
export function Menu({ children, className, label }: MenuProps) {
  const styles = menuStyles({ className });
  return (
    <ReactAriaMenu aria-label={label} className={styles.base()}>
      {children}
    </ReactAriaMenu>
  );
}

Menu.Item = MenuItem;
Menu.Section = MenuSection;
