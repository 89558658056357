import { createTV } from 'tailwind-variants';

// This ensures we can use our custom font sizes in tailwind-variants
export const tv = createTV({
  twMergeConfig: {
    classGroups: {
      'font-size': [
        'text-h1',
        'text-h2',
        'text-h3',
        'text-h4',
        'text-body1',
        'text-body2',
        'text-body3',
        'text-caption1',
        'text-caption2',
        'text-caption3',
        'text-button1',
        'text-button2',
        'text-link1',
        'text-link2',
      ],
    },
  },
});
