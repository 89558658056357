import { tv } from '../../utils';

export interface ProgressProps {
  /** Adds additional Tailwind classes to the Progress container element */
  className?: string;
  /** The color theme of the Progress */
  color?: 'primary' | 'default';
  /** The width and height of the Progress */
  size?: 'sm' | 'md' | 'lg';
}

const progressStyles = tv({
  slots: { base: 'inline-block', svg: 'animate-spin' },
  variants: {
    color: {
      primary: {
        base: 'text-primary-3',
      },
      default: {
        base: 'text-neutrals-5',
      },
    },
    size: {
      sm: {
        base: 'h-3 w-3',
      },
      md: {
        base: 'h-4 w-4',
      },
      lg: {
        base: 'h-8 w-8',
      },
    },
  },
});

/**
 * A spinning icon that indicates to a user that they must wait, often used for network requests
 *
 * @example
 * ```tsx
 * <div aria-busy="true" aria-live="polite">
 *   <Progress color="primary" size="lg" />
 * </div>
 * ```
 */
export function Progress({
  className,
  color = 'default',
  size = 'md',
}: ProgressProps) {
  const styles = progressStyles({ color, size });
  return (
    <div className={styles.base({ className })} data-testid="progress">
      <svg
        aria-hidden
        className={styles.svg()}
        fill="none"
        height="100%"
        viewBox="0 0 17 18"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1C9.05058 1 10.0909 1.20693 11.0615 1.60896C12.0321 2.011 12.914 2.60028 13.6569 3.34315C14.3997 4.08602 14.989 4.96793 15.391 5.93853C15.7931 6.90914 16 7.94943 16 9C16 10.0506 15.7931 11.0909 15.391 12.0615C14.989 13.0321 14.3997 13.914 13.6569 14.6569C12.914 15.3997 12.0321 15.989 11.0615 16.391C10.0909 16.7931 9.05057 17 8 17C6.94942 17 5.90913 16.7931 4.93853 16.391C3.96792 15.989 3.08601 15.3997 2.34314 14.6569"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
