import { useContext } from 'react';
import { MedplumClientContext } from '../../context/MedplumClient.context';

/**
 * Gets connection information for the current Medplum instance
 * @returns an object containing the base URL and client ID of the current Medplum instance
 *
 * @remarks
 * This might be a bit of an over-abstraction for the time being, however by creating a context and
 * hook to get Medplum connection information, we can future-proof the app in case we need to have
 * the ability to swap between Medplum instances
 */
export function useMedplumClient() {
  const ctx = useContext(MedplumClientContext);
  return ctx;
}
