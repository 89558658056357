import {
  MenuItem as ReactAriaMenuItem,
  Text as ReactAriaText,
} from 'react-aria-components';

import { tv } from '../../utils';
import { Avatar } from '../Avatar';
import type { AvatarProps } from '../Avatar';

export interface MenuItemProps {
  avatar?: Partial<Pick<AvatarProps, 'name' | 'url'>>;
  children: string;
  className?: string;
  description?: string;
  isDisabled?: boolean;
  onPress: () => void;
}

const menuItemStyles = tv({
  slots: {
    base: 'bg-neutrals-0 flex flex-col font-default font-normal outline-none px-4 py-3 text-body1 transition-colors',
    contentWrapper: 'flex gap-x-3 items-center',
    description: 'block text-body2',
  },
  variants: {
    isDisabled: {
      true: {
        base: 'cursor-not-allowed text-state-disabled',
        description: 'text-state-disabled',
      },
      false: {
        base: 'cursor-pointer hover:bg-neutrals-1 text-neutrals-8',
        description: 'text-neutrals-6',
      },
    },
  },
});

export function MenuItem({
  avatar,
  children,
  className,
  description,
  isDisabled = false,
  onPress,
}: MenuItemProps) {
  const styles = menuItemStyles();
  return (
    <ReactAriaMenuItem
      className={styles.base({ className, isDisabled })}
      id={children}
      isDisabled={isDisabled}
      onAction={onPress}
      textValue={children}
    >
      <div className={styles.contentWrapper()}>
        {avatar && <Avatar {...avatar} size="md" />}
        <div>
          <ReactAriaText slot="label">{children}</ReactAriaText>
          {!!description?.length && (
            <ReactAriaText
              className={styles.description({ isDisabled })}
              slot="description"
            >
              {description}
            </ReactAriaText>
          )}
        </div>
      </div>
    </ReactAriaMenuItem>
  );
}
