import type { Bundle, ExtractResource, ResourceType } from '@medplum/fhirtypes';
import { useQueryClient } from '@tanstack/react-query';
import { useMutation, useQuery } from '@vannaconnect/ui';
import { useSearchParams } from 'react-router-dom';

import { useAuthToken } from '#hooks/useAuthToken';
import { useMedplumClient } from '#hooks/useMedplumClient';
import { createAuthHeaders } from '#utils/auth';

import type {
  FhirResourceInclusion,
  FhirResourceMutations,
  QueryOutput,
  UseFhirResourceNewOpts,
  UseFhirResourceNewParams,
} from './useFhirResourceNew.types';
import {
  buildResourceBundleParams,
  buildUrlAndQueryKey,
  checkIsEnabled,
  processData,
  retryWithTokenRefreshBuilder,
  retryDelayWithTokenRefresh,
} from './useFhirResourceNew.utils';

/**
 * A hook used to query and mutate FHIR resources from the Medplum API, as well as manage included
 * data, filtration, pagination, and sorting in a fully typesafe way
 * @typeParam F A function from `@vannaconnect/fhir` that matches the resource you wish to query
 * @typeParam B A boolean that determines if the resource you wish to fetch should be a bundle
 * @typeParam I A map of inclusion keys to functions from `@vannaconnect/fhir`
 * @typeParam R A map of reverse inclusion keys to functions from `@vannaconnect/fhir`
 * @param resourceName The name of the FHIR resource you wish to fetch
 * @param resourceFn A function from `@vannaconnect/fhir`. Setting this will auto-set `F`
 * @param params Additional parameters such as default filters to be sent alongside the query
 * @param opts Optional config used when composing resource hooks to override default behavior
 * @returns an object with the query's data, loading state, error message, included resources, and
 *   functions to manage updating query string data and mutating the resource
 *
 * @example
 * ```tsx
 * import { Patient } from '@vannaconnect/fhir';
 *
 * export function MyComponent() {
 *   const {
 *     data,
 *     error,
 *     includedResources,
 *     isLoading,
 *     mutations,
 *     revIncludedResources,
 *   } = useFhirResource(
 *     'Patient',
 *     Patient,
 *     {
 *       defaultBundleParams: {
 *         filter: {
 *           active: true,
 *         },
 *         pagination: {
 *           count: 15,
 *           offset: 5,
 *         },
 *         sort: {
 *           _lastUpdated: 'desc',
 *         },
 *       },
 *       isBundle: true,
 *       isEnabled: false,
 *       postProcess: (data) => data.map((patient) => patient)
 *     }
 *   );
 * }
 * ```
 */
export function useFhirResourceNew<
  T extends ResourceType,
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(
  resourceName: T,
  params: UseFhirResourceNewParams<T, B, I, R, II, RI>,
  opts?: UseFhirResourceNewOpts,
) {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { accessToken } = useAuthToken();
  const { baseUrl } = useMedplumClient();

  const bundleParams = buildResourceBundleParams<T, B, I, R, II, RI>(
    resourceName,
    params,
    searchParams,
    setSearchParams,
  );
  const {
    mutationUrlCreate,
    mutationUrlDelete,
    mutationUrlUpdate,
    queryUrl,
    queryKey,
  } = buildUrlAndQueryKey(baseUrl, resourceName, params, bundleParams, opts);

  const authHeaders = createAuthHeaders(accessToken!);

  const res = useQuery<Bundle>(
    queryUrl,
    queryKey,
    {
      headers: { ...authHeaders },
      method: 'get',
    },
    {
      enabled: checkIsEnabled(params),
      retry: retryWithTokenRefreshBuilder(queryClient),
      retryDelay: retryDelayWithTokenRefresh,
    },
  );

  const [createResourceMutation] = useMutation<
    ExtractResource<T>,
    Omit<ExtractResource<T>, 'id'>
  >(
    mutationUrlCreate,
    {
      headers: { ...authHeaders },
      method: 'post',
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [`${resourceName}-new`],
        });
      },
      retry: retryWithTokenRefreshBuilder(queryClient),
      retryDelay: retryDelayWithTokenRefresh,
    },
  );

  const [deleteResourceMutation] = useMutation<ExtractResource<T>, {}>(
    mutationUrlDelete,
    {
      headers: { ...authHeaders },
      method: 'delete',
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [`${resourceName}-new`],
        });
      },
      retry: retryWithTokenRefreshBuilder(queryClient),
      retryDelay: retryDelayWithTokenRefresh,
    },
  );

  const [updateResourceMutation] = useMutation<
    ExtractResource<T>,
    ExtractResource<T>
  >(
    mutationUrlUpdate,
    {
      headers: { ...authHeaders },
      method: 'put',
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [`${resourceName}-new`],
        });
      },
      retry: retryWithTokenRefreshBuilder(queryClient),
      retryDelay: retryDelayWithTokenRefresh,
    },
  );

  const mutations = {
    create: createResourceMutation,
    delete: deleteResourceMutation,
    update: updateResourceMutation,
  } as unknown as FhirResourceMutations<T>;

  const {
    data,
    includedResources,
    includedResourcesIterative,
    revIncludedResources,
    revIncludedResourcesIterative,
  } = processData(resourceName, res.data, params);

  return {
    ...res,
    bundleParams,
    data,
    includedResources,
    includedResourcesIterative,
    mutations,
    revIncludedResources,
    revIncludedResourcesIterative,
  } as unknown as QueryOutput<T, B, I, R, II, RI>;
}
