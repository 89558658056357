import { useState } from 'react';

import { tv } from '../../utils';

export interface AvatarProps {
  /** Adds additional Tailwind classes to the Avatar container element */
  className?: string;
  /** A name used as a fallback when the url fails to load or is not provided */
  name?: {
    firstName?: string;
    lastName?: string;
  };
  /** The width and height of the Avatar */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /** The URL of the image to display */
  url?: string;
}

const avatarStyles = tv({
  slots: {
    base: 'bg-neutrals-6 flex font-default font-medium items-center justify-center overflow-hidden rounded-full text-center text-neutrals-1',
    icon: 'h-full p-1 w-full',
    img: 'h-full object-cover w-full',
  },
  variants: {
    isEmpty: {
      true: {
        base: 'bg-neutrals-1 border border-neutrals-4 border-dashed text-neutrals-5',
      },
    },
    size: {
      xs: {
        // 28px
        // This font size is bespoke, design should add this to our Figma type variables
        base: 'h-7 w-7 text-[10px]',
        icon: 'p-px',
      },
      sm: {
        // 36px
        base: 'h-9 w-9 text-body3',
      },
      md: {
        // 44px
        base: 'h-11 w-11 text-body1',
      },
      lg: {
        // 56px
        base: 'h-14 w-14 text-h3',
      },
      xl: {
        // 64px (not in design system figma, used in member profile)
        base: 'h-16 w-16 text-h1',
      },
    },
  },
});

/**
 * Displays an image. When no image URL is provided the initials of the provided name are
 * displayed. When no name and and no image URL are provided a fallback icon is displayed
 *
 * @example
 * ```
 * <Avatar
 *   name={{ firstName: 'Robert', lastName: 'Linder'}}
 *   size="sm"
 *   url="https://picsum.photos/id/602/512/512" />
 * ```
 */
export function Avatar({ className, name, size = 'md', url }: AvatarProps) {
  const [isImgError, setIsImgError] = useState(false);

  const shouldRenderImg = !!url?.length && !isImgError;
  const shouldRenderName =
    !shouldRenderImg && (!!name?.firstName?.length || !!name?.lastName?.length);
  const isEmpty = !shouldRenderImg && !shouldRenderName;

  const styles = avatarStyles({ isEmpty, size });

  return (
    <div aria-hidden className={styles.base({ className })}>
      {shouldRenderImg && (
        <img
          alt=""
          className={styles.img()}
          data-testid="avatar-image"
          onError={() => setIsImgError(true)}
          src={url}
        />
      )}
      {shouldRenderName && (
        <div data-testid="avatar-initials">
          {name.firstName?.[0]}
          {name.lastName?.[0]}
        </div>
      )}
      {isEmpty && (
        <div aria-hidden data-testid="avatar-icon">
          ?
        </div>
      )}
    </div>
  );
}
