import { AnimatePresence, m } from 'framer-motion';
import type { ReactNode } from 'react';
import {
  Dialog as ReactAriaDialog,
  DialogTrigger as ReactAriaDialogTrigger,
  Heading as ReactAriaHeading,
  OverlayTriggerStateContext as ReactAriaOverlayTriggerStateContext,
  Popover as ReactAriaPopover,
} from 'react-aria-components';
import type { PopoverProps as ReactAriaPopoverProps } from 'react-aria-components';

import { tv } from '../../utils';

export interface PopoverProps
  extends Omit<
    ReactAriaPopoverProps,
    'children' | 'isOpen' | 'onOpenChange' | 'trigger' | 'triggerRef'
  > {
  /** The content of the Popover */
  children:
    | ReactNode
    | ((params: { setOpen: (isOpen: boolean) => void }) => ReactNode);
  /** Adds additional Tailwind classes to the Popover container element */
  className?: string;
  /** An accessible label for screen readers to describe the popover */
  label: string;
  /** Controls the position around the trigger element that the Popover will render */
  placement?: ReactAriaPopoverProps['placement'];
  /** A component that will open the popup when clicked or tapped */
  trigger: ReactNode;
}

const popoverStyles = tv({
  slots: {
    base: 'bg-neutrals-0 outline-0 rounded-lg shadow',
  },
});

const MotionReactAriaPopover = m(ReactAriaPopover);

/**
 * Used to display content on top of a trigger element after clicking on it
 *
 * @example
 * ```
 * import { Button } from '@vannaconnect/button';
 *
 * <Popover label="Test Popover" trigger={<Button>Open Popover</Button>}>
 *   This is a test popover
 * </Popover>
 * ```
 */
export function Popover({
  children,
  className,
  label,
  placement = 'bottom left',
  trigger,
  ...reactAriaProps
}: PopoverProps) {
  const styles = popoverStyles();
  return (
    <ReactAriaDialogTrigger>
      {trigger}
      <ReactAriaOverlayTriggerStateContext.Consumer>
        {({ isOpen, setOpen }) => (
          <AnimatePresence>
            {isOpen && (
              <MotionReactAriaPopover
                {...reactAriaProps}
                isOpen
                onOpenChange={setOpen}
                placement={placement}
                initial={{
                  opacity: 0,
                  y: -10,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                  y: -10,
                }}
                transition={{
                  type: 'spring',
                  bounce: 0.2,
                  duration: 0.15,
                }}
              >
                <ReactAriaDialog className={styles.base({ className })}>
                  <ReactAriaHeading className="sr-only" slot="title">
                    {label}
                  </ReactAriaHeading>
                  {typeof children === 'function'
                    ? children({ setOpen })
                    : children}
                </ReactAriaDialog>
              </MotionReactAriaPopover>
            )}
          </AnimatePresence>
        )}
      </ReactAriaOverlayTriggerStateContext.Consumer>
    </ReactAriaDialogTrigger>
  );
}
