import type { ReactNode } from 'react';

import { MedplumClientContext } from '../../context/MedplumClient.context';
import type { MedplumClientContextParams } from '../../context/MedplumClient.context';

export interface MedplumClientProviderProps extends MedplumClientContextParams {
  children: ReactNode;
}

export function MedplumClientProvider({
  children,
  ...ctx
}: MedplumClientProviderProps) {
  return (
    <MedplumClientContext.Provider value={ctx}>
      {children}
    </MedplumClientContext.Provider>
  );
}
