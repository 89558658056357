import { Type } from '@sinclair/typebox';
import type { Static } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

export const ConfigSchema = Type.Object({
  VITE_MEDPLUM_BASE_URL: Type.String({ minLength: 1 }),
  VITE_OKTA_AUTHORIZE_URL: Type.String({ minLength: 1 }),
  VITE_OKTA_CLIENT_ID: Type.String({ minLength: 1 }),
  VITE_MEDPLUM_CLIENT_ID: Type.String({ minLength: 1 }),
  VITE_POPHEALTH_BASE_URL: Type.String({
    minLength: 1,
    default: 'https://example.com',
  }),
  VITE_SENTRY_DSN: Type.Optional(Type.String({ default: undefined })),
  VITE_VANNA_API_BASE_URL: Type.String({ minLength: 1 }),
  //TODO --MS cleanup, temporary
  VITE_VANNA_ADT_SERVICE_API_BASE_URL: Type.String({ minLength: 1 }),
});

export const config = Value.Default(ConfigSchema, import.meta.env) as Static<
  typeof ConfigSchema
>;

export const validateConfig = () => {
  const isConfigValid = Value.Check(ConfigSchema, config);
  if (!isConfigValid) {
    console.error('The following errors occurred while validating your .env:', [
      ...Value.Errors(ConfigSchema, config),
    ]);
    throw new Error('Environment validation failed');
  }
};
