import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import { LATEST_POPULATION_ID } from './constants';

// This is ugly, but it's a symptom of our development stage (FE & BE).
// We ultimately want the population-health module endpoint, the member-
// selection endpoint, the population id param, and then the lead segment
// param, aka: `population-health/member-selection/:popId/:leadSegment`.
// To achieve this, we need to cover every possible navigable permutation
// and use outlets/redirects as necessary.

export const populationHealthRoutes = [
  {
    path: 'population-health',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Navigate to={`member-selection`} />,
      },
      {
        path: 'member-selection',
        element: <Navigate to={`${LATEST_POPULATION_ID}/leads`} />,
      },
      {
        path: 'member-selection/:populationId',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Navigate to={`leads`} />,
          },
          {
            path: ':leadSegment',
            lazy: async () => {
              const { MemberSelectionPage } = await import(
                './pages/MemberSelection'
              );
              return { Component: MemberSelectionPage };
            },
          },
        ],
      },
    ],
  },
] satisfies RouteObject[];
