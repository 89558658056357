import { createElement } from 'react';
import type { HTMLAttributes, HTMLProps, ReactHTML, ReactNode } from 'react';

import { tv } from '../../utils';
import type { TextPreset } from './Text.types';
import { inferDefaultEl } from './Text.utils';

export interface TextProps<Element extends HTMLElement>
  extends HTMLProps<Element> {
  /** The content of the Text */
  children?: ReactNode;
  /** Adds additional Tailwind classes to the Text element */
  className?: string;
  /** Changes the underlying HTML element rendered by the Text */
  el?: keyof ReactHTML;
  /** Modifies font family, size, weight, and line height based on the provided preset */
  preset?: TextPreset;
}

const textStyles = tv({
  slots: { base: 'font-default text-neutrals-8' },
  variants: {
    preset: {
      // There's probably a cleaner way to do this than making each of these individual variants,
      // but I'm going with the KISS approach on this one. Also an additional note, tailwind's
      // naming for font weight 400 is "normal", while Inter / Figma's name is "regular". When I
      // originally wrote this none of my weights worked because I didn't know this
      'h1-semibold': { base: 'font-semibold text-h1' },
      'h1-medium': { base: 'font-medium text-h1' },
      'h2-semibold': { base: 'font-semibold text-h2' },
      'h2-medium': { base: 'font-medium text-h2' },
      'h3-semibold': { base: 'font-semibold text-h3' },
      'h3-medium': { base: 'font-medium text-h3' },
      'h4-semibold': { base: 'font-semibold text-h4' },
      'h4-medium': { base: 'font-medium text-h4' },
      'body1-semibold': { base: 'font-semibold text-body1' },
      'body1-medium': { base: 'font-medium text-body1' },
      'body1-regular': { base: 'font-normal text-body1' },
      'body2-semibold': { base: 'font-semibold text-body2' },
      'body2-medium': { base: 'font-medium text-body2' },
      'body2-regular': { base: 'font-normal text-body2' },
      'body3-semibold': { base: 'font-semibold text-body3' },
      'body3-medium': { base: 'font-medium text-body3' },
      'body3-regular': { base: 'font-normal text-body3' },
      caption1: { base: 'font-normal text-caption1' },
      caption2: { base: 'font-normal text-caption2' },
      caption3: { base: 'font-normal text-caption3' },
    },
  },
});

/**
 * Applies standardized typography styles to your element of choice
 *
 * @example
 * ```tsx
 * <Text color="primary-p3" el="h1" preset="h3-semibold"
 * ```
 */
export function Text<Element extends HTMLElement = HTMLSpanElement>({
  children,
  className,
  el,
  preset = 'body1-medium',
  ...htmlProps
}: TextProps<Element>) {
  const styles = textStyles({ preset });
  // In order to take advantage of the accessibility benefits from semantic HTML, this component
  // leverages the low level React API to allow developers to pick the HTML element rendered. This
  // is useful in cases where design wants an element to use the H3 styles, however it's the first
  // heading on the page so it should use the H1 element
  return createElement<HTMLAttributes<Element>, Element>(
    el || inferDefaultEl(preset),
    { ...htmlProps, className: styles.base({ className }) },
    children,
  );
}

Text.styles = textStyles;
