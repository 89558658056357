import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';

import {
  MAX_RETRIES_MUTATION,
  MAX_RETRIES_QUERY,
} from '../../constants/tanstackQuery';

export interface QueryProviderProps {
  children: ReactNode;
}

export function QueryProvider({ children }: QueryProviderProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Queries are considered stale and invalidated after 10 minutes to reduce network calls
        staleTime: 600000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: MAX_RETRIES_QUERY,
      },
      mutations: {
        retry: MAX_RETRIES_MUTATION,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
}
