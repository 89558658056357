import type { Practitioner } from '@medplum/fhirtypes';

import type { VannaResource } from '../types';
import * as fhirpaths from './practitioner.fhirpaths';

/**
 * Gets a Practitioner's first and last name for usage in an `<Avatar />` component
 * @param fhir A Practitioner FHIR resource
 * @returns an `object` with a `firstName` and `lastName` property representing the Practitioner's
 *   given name and family name
 *
 * @example
 * ```typescript
 * const displayName = vannaPractitioner.getAvatarName(Practitioner);
 * ```
 */
function getAvatarName(fhir: Practitioner): {
  firstName?: string;
  lastName?: string;
} {
  const name = fhirpaths.getName(fhir);
  const given = name?.given || [];
  const family = name?.family;

  return {
    firstName: given[0],
    lastName: family,
  };
}

/**
 * Gets a Practitioner's display name
 * @param fhir A Practitioner FHIR resource
 * @returns a `string` representing the Practitioner's given name and family name
 *
 * @example
 * ```typescript
 * const displayName = vannaPractitioner.getDisplayName(Practitioner);
 * ```
 */
function getDisplayName(fhir: Practitioner): string {
  const name = fhirpaths.getName(fhir);
  const given = name?.given || [];
  const family = name?.family;

  return [...given, family].filter((n) => n).join(' ');
}

/**
 * Gets a Practitioner's profile picture URL
 * @param fhir A Practitioner FHIR resource
 * @returns a `string` representing the Practitioner's `photo.url`
 *
 * @example
 * ```typescript
 * const profilePictureUrl = vannaPractitioner.getProfilePictureUrl(Practitioner);
 * ```
 */
function getProfilePictureUrl(fhir: Practitioner): string | undefined {
  return fhirpaths.getProfilePictureUrl(fhir);
}

export type VannaPractitioner = VannaResource<Practitioner>;
export const vannaPractitioner = {
  name: {
    getAvatarName,
    getDisplayName,
  },
  photo: {
    getProfilePictureUrl,
  },
} satisfies VannaPractitioner;
