import { useSearchParams } from 'react-router-dom';

import { safeJsonParse } from '#utils/json';

export function useDrawerParams<T extends object>() {
  const [searchParams, setSearchParams] = useSearchParams();

  const rawDrawerParams = searchParams.get('drawerParams');
  const parsedDrawerQs = {
    drawerName: searchParams.get('drawer') as keyof T | null,
    drawerParams: rawDrawerParams
      ? (safeJsonParse(decodeURIComponent(rawDrawerParams)) as Record<
          string,
          any
        >)
      : {},
  };

  /** Adds drawer=:name and drawerParams=:params to query string */
  const openDrawer = <N extends keyof T>(name: N, params: T[N]) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      drawer: name as string,
      drawerParams: JSON.stringify(params),
    });
  };

  /** Removed drawer and drawerParams from query string */
  const closeDrawer = () => {
    setSearchParams({
      ...Object.fromEntries(
        [...searchParams.entries()].filter(
          ([key]) => key !== 'drawer' && key !== 'drawerParams',
        ),
      ),
    });
  };

  return [openDrawer, closeDrawer, parsedDrawerQs] as const;
}
