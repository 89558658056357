import type { ReactHTML } from 'react';

import type { TextPreset } from './Text.types';

// In order to follow semantic HTML for accessibility, our text component allows engineers to
// manually select which HTML element they would like to render their text inside of. This is great
// for instances such as "the first heading on the page has h3 styles, but needs an h1 element",
// however it can be inconvenient to have to specify your element every single time. This function
// maps font presets to a reasonable default element which can be overridden when needed
export const inferDefaultEl = (preset: TextPreset): keyof ReactHTML => {
  if (preset[0] === 'h' && Number.parseInt(preset[1], 10) <= 4) {
    // A little trick to get h1 - h4 without 4 if statements
    return `h${preset[1]}` as keyof ReactHTML;
  }
  if (preset.includes('caption')) {
    return 'caption';
  }
  // Any body text can get thrown into a paragraph
  return 'p';
};
