// DO NOT COPY THIS IMPORT PATTERN
// This is ugly but currently there is not a better way to import files from outside
// the /typescript monorepo but from within the top level git repo. All fhirpath
// imports of this nature should happen here to simplify removal later.
import bundleFhirPaths from '../../../../../fhir/ig/input/fsh/Resources/Bundle/Bundle-fhirpath.json';
import patientFhirPaths from '../../../../../fhir/ig/input/fsh/Resources/Patient/Patient-fhirpath.json';
import practitionerFhirPaths from '../../../../../fhir/ig/input/fsh/Resources/Practitioner/Practitioner-fhirpath.json';
import relatedPersonFhirPaths from '../../../../../fhir/ig/input/fsh/Resources/RelatedPerson/RelatedPerson-fhirpath.json';

export const bundle = bundleFhirPaths;
export const patient = patientFhirPaths;
export const practitioner = practitionerFhirPaths;
export const relatedPerson = relatedPersonFhirPaths;
