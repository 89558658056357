import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { Icon } from '../Icon';
import type { IconName } from '../Icon';
import type { snackbarTypes } from './Snackbar.utils';
import { Text } from '../Text';

export function Snackbar() {
  const iconMap = {
    error: { name: 'exclamation-circle-mini', color: 'text-critical-3' },
    warning: { name: 'exclamation-triangle-mini', color: 'text-warning-3' },
    info: { name: 'information-circle-mini', color: 'text-primary-3' },
    success: { name: 'check-circle-mini', color: 'text-success-3' },
  };

  // TODO: Swap out react-hot-toast with React Aria's toast implementation
  return (
    <Toaster
      toastOptions={{
        className:
          'bg-primary-5 py-4 px-3 text-neutrals-0 w-80 justify-start items-start',
        duration: 5000,
        style: { backgroundColor: '#052B48' },
      }}
    >
      {(t: any) => (
        <ToastBar toast={t}>
          {() => (
            <>
              <Icon
                name={iconMap[t.icon as snackbarTypes].name as IconName}
                className={
                  iconMap[t.icon as snackbarTypes].color + ' w-5 mr-2 mt-px'
                }
              />
              <Text
                preset="body2-regular"
                className="flex flex-auto w-5 text-neutrals-0"
              >
                {t.message}
              </Text>
              {t.link && (
                <a href={t.link} className="underline mr-6 text-neutrals-0">
                  <Text preset="body2-medium" className="text-neutrals-0">
                    View
                  </Text>
                </a>
              )}
              {t.showCloseButton && (
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="flex mt-0.5"
                >
                  <Icon name="x-mark" className="text-neutrals-0" />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
