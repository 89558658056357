import type { ReactNode } from 'react';
import {
  Header as ReactAriaHeader,
  Section as ReactAriaSection,
} from 'react-aria-components';

import { tv } from '../../utils';

export interface MenuSectionProps {
  /** The items to be rendered in the MenuSection */
  children: ReactNode;
  /** Adds additional Tailwind classes to the MenuSection container element */
  className?: string;
  /** The title of the MenuSection, displayed at the top */
  title: string;
}

const menuSectionStyles = tv({
  slots: {
    base: 'flex flex-col w-auto',
    header:
      'bg-neutrals-1 block font-default font-normal px-4 py-2 text-body2 text-neutrals-7 rounded',
  },
});

/**
 * Creates a distinct section for a complex menus with a unique title and its own menu items
 *
 * @example
 * ```tsx
 * <Menu.Section title="Some Section">
 *   <Menu.Item onPress={onSectionItem1Click}>Section Item 1</Menu.Item>
 * </Menu.Section>
 * ```
 */
export function MenuSection({ children, title }: MenuSectionProps) {
  const styles = menuSectionStyles();
  return (
    <ReactAriaSection className={styles.base()}>
      <ReactAriaHeader className={styles.header()}>{title}</ReactAriaHeader>
      {children}
    </ReactAriaSection>
  );
}
