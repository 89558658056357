import { Suspense, lazy } from 'react';
import type { ReactNode } from 'react';

import { useAuthToken } from '../../hooks/useAuthToken';
import { useMedplumClient } from '../../hooks/useMedplumClient';

const LoginPage = lazy(() =>
  import('../../components/LoginPage').then(({ LoginPage: Component }) => ({
    default: Component,
  })),
);

export interface AuthGateProps {
  /** The auth-gated content of your app */
  children: ReactNode;
  /** The OAuth authorization endpoint of your Okta instance */
  oktaAuthorizeUrl: string;
  /** Client ID used for Okta OAuth */
  oktaClientId: string;
}

/**
 * A wrapper component that prevents children from rendering until authenticated with Medplum. If
 * a user is unauthenticated, a login page will be rendered in place of the component children
 *
 * @example
 * ```tsx
 * <AuthGate>
 *   <h1>This Content is Protected!</h1>
 * </AuthGate>
 * ```
 */
export function AuthGate({
  children,
  oktaAuthorizeUrl,
  oktaClientId,
}: AuthGateProps) {
  const medplumClient = useMedplumClient();
  const { accessToken, isLoading } = useAuthToken();

  if (isLoading) {
    // Right now we're returning nothing when the auth token is fetching, however we might want to
    // add a skeleton loader for the app here at some point
    return null;
  }

  if (!accessToken && !isLoading) {
    return (
      <Suspense>
        <LoginPage
          currentUrl={window.location.href}
          medplumBaseUrl={medplumClient.baseUrl}
          medplumClientId={medplumClient.clientId}
          oktaAuthorizeUrl={oktaAuthorizeUrl}
          oktaClientId={oktaClientId}
        />
      </Suspense>
    );
  }

  return children;
}
