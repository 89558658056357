import type { Bundle, ExtractResource, ResourceType } from '@medplum/fhirtypes';
import { vannaBundle } from '@vannaconnect/fhir/v2';
import { deepMergeObject } from '@vannaconnect/utils/merging';
import type { SetURLSearchParams } from 'react-router-dom';

import { AUTH_TOKENS_ENDPOINT } from '#constants/auth';
import type {
  FhirResourceBundleParams,
  FhirResourceFilter,
  FhirResourceInclusion,
  FhirResourcePagination,
  FhirResourceSort,
  InclusionOutput,
  UseFhirResourceNewOpts,
  UseFhirResourceNewParams,
} from './useFhirResourceNew.types';
import type { QueryClient } from '@tanstack/react-query';

export const DEFAULT_COUNT = 10;
export const DEFAULT_OFFSET = 0;

/**
 * Creates a `FhirResourceBundleParams` object to be used with `useFhirResource`
 * @typeParam F A function from `@vannaconnect/fhir` that matches the resource you wish to query
 * @typeParam B A boolean that determines if the resource you wish to fetch should be a bundle
 * @typeParam I A map of inclusion keys to functions from `@vannaconnect/fhir`
 * @typeParam R A map of reverse inclusion keys to functions from `@vannaconnect/fhir`
 * @typeParam II A map of iterative inclusion keys to functions from `@vannaconnect/fhir`
 * @typeParam RI A map of iterative reverse inclusion keys to functions from `@vannaconnect/fhir`
 * @param resourceName The name of the FHIR resource, this is used to extract params from the URL
 * @param params The `UseFhirResourceNewParams` object used to compose the hook
 * @param searchParams The current React Router URLSearchParams object
 * @param setSearchParams The React Router URLSearchParams setter function
 * @returns an object representing the current combined values of filter, pagination, and sort
 *   alongside setter functions for each that modify the URL when called
 */
export function buildResourceBundleParams<
  T extends ResourceType,
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(
  resourceType: T,
  params: UseFhirResourceNewParams<T, B, I, R, II, RI>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): FhirResourceBundleParams<B> {
  if (!params.isBundle) {
    // Although this is being cast as never, object values are still being set here to prevent
    // white screen crashes in case these are mistakenly used with TS errors ignored
    return {
      filter: {},
      pagination: {},
      sort: {},
      setFilter: () => {
        throw new Error(
          `setFilter for ${resourceType} can only be called when isBundle = true`,
        );
      },
      setPagination: () => {
        throw new Error(
          `setPagination for ${resourceType} can only be called when isBundle = true`,
        );
      },
      setSort: () => {
        throw new Error(
          `setSort for ${resourceType} can only be called when isBundle = true`,
        );
      },
    } as never;
  }

  const resourceSearchParams = getResourceGuardedSearchParams(
    resourceType,
    searchParams,
  );

  // Question: Should the logic for merging default filter, pagination, and sort be abstracted out
  // of this function? It's kinda big and hard to read
  const defaultFilters: FhirResourceFilter =
    params.defaultBundleParams?.filter ?? {};
  const filter: FhirResourceFilter = {
    ...defaultFilters,
  };

  // Add anything from the URL bar that's not _count, _offset, or _sort to the filters object
  [...resourceSearchParams.entries()].forEach(([key, value]) => {
    // TODO --MS family was added as a bandaid
    if (['_count', '_offset', '_sort', 'family'].includes(key)) {
      return;
    }
    filter[key] = value.includes(',') ? value.split(',') : value;
  });

  const defaultPagination: NonNullable<FhirResourcePagination> = {
    count: params.defaultBundleParams?.pagination?.count ?? DEFAULT_COUNT,
    offset: params.defaultBundleParams?.pagination?.offset ?? DEFAULT_OFFSET,
  };
  const pagination: NonNullable<FhirResourcePagination> = {
    ...defaultPagination,
  };

  const countFromUrl = resourceSearchParams.get('_count');
  if (countFromUrl) {
    pagination.count = parseInt(countFromUrl, 10);
  }

  const offsetFromUrl = resourceSearchParams.get('_offset');
  if (offsetFromUrl) {
    pagination.offset = parseInt(offsetFromUrl, 10);
  }

  const defaultSort = params.defaultBundleParams?.sort ?? {};
  const sort: FhirResourceSort = {
    ...defaultSort,
  };

  resourceSearchParams
    .get('_sort')
    ?.split(',')
    .forEach((sortStr) => {
      if (sortStr[0] === '-') {
        sort[sortStr.slice(1)] = 'desc';
        return;
      }
      sort[sortStr] = 'asc';
    });

  // TODO: When we transition to Tanstack Router, this code will likely be significantly simplified

  const bundleParams = {
    filter: removeNullUndefinedValues(filter),
    pagination: pagination,
    sort: removeNullUndefinedValues(sort),
    setFilter: (newFilters) => {
      Object.entries(newFilters).forEach(([key, value]) => {
        const keyWithResource = `${key}[${resourceType}]`;
        if (
          value === undefined ||
          value === null ||
          (Array.isArray(value) && !value.length)
        ) {
          searchParams.delete(keyWithResource);
          return;
        }
        searchParams.set(
          keyWithResource,
          Array.isArray(value) ? value.join(',') : value,
        );
      });

      const newFilterKeyMap = Object.keys(newFilters).map(
        (newFilterKey) => `${newFilterKey}[${resourceType}]`,
      );
      Object.keys(searchParams).forEach((key) => {
        if (
          !key.includes(`[${resourceType}]`) ||
          key.includes('_sort') ||
          key.includes('_count') ||
          key.includes('_offset')
        ) {
          return;
        }
        if (!newFilterKeyMap.includes(key)) {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    },
    setPagination: (newPagination) => {
      const countQueryParam = `_count[${resourceType}]`;
      if (newPagination.count) {
        searchParams.set(countQueryParam, newPagination.count.toString());
      } else {
        searchParams.delete(countQueryParam);
      }

      const offsetQueryParam = `_offset[${resourceType}]`;
      if (newPagination.offset) {
        searchParams.set(
          `_offset[${resourceType}]`,
          newPagination.offset.toString(),
        );
      } else {
        searchParams.delete(offsetQueryParam);
      }

      setSearchParams(searchParams);
    },
    setSort: (newSort) => {
      const sortQueryParam = `_sort[${resourceType}]`;
      if (!Object.entries(newSort).filter(([_key, value]) => !!value).length) {
        searchParams.delete(sortQueryParam);
      } else {
        searchParams.set(
          sortQueryParam,
          Object.entries(newSort)
            .map(([key, value]) => `${value === 'desc' ? '-' : ''}${key}`)
            .join(','),
        );
      }
      setSearchParams(searchParams);
    },
  } as FhirResourceBundleParams<B>;

  return bundleParams;
}

export function buildUrlAndQueryKey<
  T extends ResourceType,
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(
  baseUrl: string,
  resourceType: T,
  params: UseFhirResourceNewParams<T, B, I, R, II, RI>,
  bundleParams: FhirResourceBundleParams<B>,
  opts?: UseFhirResourceNewOpts,
) {
  // These query string params will be sent directly to Medplum and will never be directly ingested
  // by our app
  const medplumQueryParams = new URLSearchParams();

  // These query key params will look similar to the above query string params, however they'll
  // be used internally (never sent to Medplum) to create unique query keys for each provided param
  // to reduce latency
  const tanstackQueryKeyParams: Record<string, unknown> = {};

  // For non-bundle resources, all we need to add to the Medplum query string is the resource's ID
  // and pagination values that ensure only a single resource is selected
  if (!params.isBundle) {
    tanstackQueryKeyParams['id'] = params.id;
    medplumQueryParams.set('_id', params.id!);

    // Count and offset don't need to be set here for Tanstack Query
    medplumQueryParams.set('_count', '1');
    medplumQueryParams.set('_offset', '0');
  }

  // For bundled resources things get a little more complicated. We'll be composing our Medplum
  // query string params and our Tanstack query key based off of the bundleParams object generated
  // earlier in the hook
  if (params.isBundle) {
    Object.entries(bundleParams.filter).forEach(([key, value]) => {
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && !value.length)
      ) {
        return;
      }
      const parsedValue = Array.isArray(value) ? value.join(',') : value;

      tanstackQueryKeyParams[key] = value;
      medplumQueryParams.set(key, parsedValue);
    });

    // We'll always have bundle params for pagination thanks to our default values
    tanstackQueryKeyParams['_count'] = bundleParams.pagination.count;
    tanstackQueryKeyParams['_offset'] = bundleParams.pagination.offset;
    medplumQueryParams.set('_count', `${bundleParams.pagination.count}`);
    medplumQueryParams.set('_offset', `${bundleParams.pagination.offset}`);
    medplumQueryParams.delete('offset');
    medplumQueryParams.delete('count');

    const sortEntries = Object.entries(bundleParams.sort);
    if (sortEntries.length) {
      const sortStr = sortEntries
        .map(([key, direction]) => `${direction === 'desc' ? '-' : ''}${key}`)
        .join(',');
      tanstackQueryKeyParams['_sort'] = sortStr;
      medplumQueryParams.set('_sort', sortStr);
    }
  }

  // The last thing that we have to set before we can send the request to Medplum is the query
  // params for included and reverse included resources. These can be sent on both bundles and
  // individual resources
  if (params.include && Object.keys(params.include).length) {
    const includeStr = Object.keys(params.include).join(',');
    tanstackQueryKeyParams['_include'] = includeStr;

    medplumQueryParams.set('_include', includeStr);
  }

  if (params.includeIterate && Object.keys(params.includeIterate).length) {
    const includeIterateStr = Object.keys(params.includeIterate).join(',');
    tanstackQueryKeyParams['_include:iterate'] = includeIterateStr;

    medplumQueryParams.set('_include:iterate', includeIterateStr);
  }

  if (params.revInclude && Object.keys(params.revInclude).length) {
    const revIncludeStr = Object.keys(params.revInclude).join(',');

    tanstackQueryKeyParams['_revinclude'] = revIncludeStr;
    medplumQueryParams.set('_revinclude', revIncludeStr);
  }

  if (
    params.revIncludeIterate &&
    Object.keys(params.revIncludeIterate).length
  ) {
    const revIncludeIterateStr = Object.keys(params.revIncludeIterate).join(
      ',',
    );
    tanstackQueryKeyParams['_revinclude:iterate'] = revIncludeIterateStr;

    medplumQueryParams.set('_revinclude:iterate', revIncludeIterateStr);
  }

  const medplumQueryString = medplumQueryParams.toString();
  const url = `${baseUrl}/fhir/R4/${resourceType}`;
  const urlWithId = `${url}/${opts?.customSingleResourceIdentifier ?? params.id}`;
  const urlWithBundleQs = `${url}?${medplumQueryString}`;
  const queryUrl =
    params.isBundle || !opts?.customSingleResourceIdentifier
      ? urlWithBundleQs
      : urlWithId;
  return {
    mutationUrlCreate: url,
    mutationUrlDelete: url,
    mutationUrlUpdate: url,
    queryUrl,
    queryKey: [`${resourceType}-new`, tanstackQueryKeyParams] as [
      string,
      object,
    ],
  };
}

/**
 * Determines whether or not a query is enabled
 * @param params The query parameters
 * @returns params.isEnabled if defined, otherwise returns true if query is a bundle, and returns
 *   true if params.id is provided when the query if not a bundle
 */
export function checkIsEnabled<
  T extends ResourceType,
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(params: UseFhirResourceNewParams<T, B, I, R, II, RI>): boolean {
  if (typeof params.isEnabled !== 'undefined') {
    return params.isEnabled;
  }
  if (params.isBundle) {
    return true;
  }
  return !!params.id?.length;
}

export function processData<
  T extends ResourceType,
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(
  resourceType: T,
  data: Bundle | null,
  params: UseFhirResourceNewParams<T, B, I, R, II, RI>,
):
  | {
      data: null;
      includedResources: null;
      includedResourcesIterative: null;
      revIncludedResources: null;
      revIncludedResourcesIterative: null;
    }
  | {
      data: B extends true ? ExtractResource<T>[] : ExtractResource<T>;
      includedResources: InclusionOutput<I>;
      includedResourcesIterative: InclusionOutput<II>;
      revIncludedResources: InclusionOutput<R>;
      revIncludedResourcesIterative: InclusionOutput<RI>;
    } {
  if (!data) {
    return {
      data: null,
      includedResources: null,
      includedResourcesIterative: null,
      revIncludedResources: null,
      revIncludedResourcesIterative: null,
    };
  }

  // If we use a custom resource identifier and the resource isn't a bundle, we have to use a hacky
  // workaround and typecase / return the original request with placeholder inclusions
  if (data.resourceType !== 'Bundle') {
    return {
      data: data as B extends true ? ExtractResource<T>[] : ExtractResource<T>,
      includedResources: {} as InclusionOutput<I>,
      includedResourcesIterative: {} as InclusionOutput<II>,
      revIncludedResources: {} as InclusionOutput<R>,
      revIncludedResourcesIterative: {} as InclusionOutput<RI>,
    };
  }

  const resources = vannaBundle.entry.getResources<ExtractResource<T>>(data);

  const includedResources = Object.fromEntries(
    Object.keys(params.include ?? {}).map((key) => [key, []]),
  ) as unknown as InclusionOutput<I>;

  const includedResourcesIterative = Object.fromEntries(
    Object.keys(params.includeIterate ?? {}).map((key) => [key, []]),
  ) as unknown as InclusionOutput<II>;

  const revIncludedResources = Object.fromEntries(
    Object.keys(params.revInclude ?? {}).map((key) => [key, []]),
  ) as unknown as InclusionOutput<R>;

  const revIncludedResourcesIterative = Object.fromEntries(
    Object.keys(params.revIncludeIterate ?? {}).map((key) => [key, []]),
  ) as unknown as InclusionOutput<RI>;

  const originalResources = resources.filter((resource) => {
    if (resource.resourceType === resourceType) {
      return true;
    }
    // Yeah I know it's not very functional for a map to also modify a record, but this saves
    // iteration cycles so paradigms by damned
    const includeKey = Object.entries(params.include || {}).find(
      ([_key, includedResourceType]) =>
        includedResourceType === resource.resourceType,
    )?.[0] as keyof I | undefined;

    const includeIterKey = Object.entries(params.includeIterate || {}).find(
      ([_key, includedResourceType]) =>
        includedResourceType === resource.resourceType,
    )?.[0] as keyof II | undefined;

    const revIncludeKey = Object.entries(params.revInclude || {}).find(
      ([_key, includedResourceType]) =>
        includedResourceType === resource.resourceType,
    )?.[0] as keyof R | undefined;

    const revIncludeIterKey = Object.entries(
      params.revIncludeIterate || {},
    ).find(
      ([_key, includedResourceType]) =>
        includedResourceType === resource.resourceType,
    )?.[0] as keyof RI | undefined;

    if (
      !includeKey &&
      !includeIterKey &&
      !revIncludeKey &&
      !revIncludeIterKey
    ) {
      console.error(
        `A resource of type ${resource.resourceType} with no resolver function was found in a bundle of ${resourceType}. This resource has been excluded from the parsed data`,
        resource,
      );
    }

    if (includeKey) {
      (includedResources[includeKey] as ExtractResource<I[keyof I]>[]) = [
        ...(includedResources[includeKey] as ExtractResource<I[keyof I]>[]),
        resource as unknown as ExtractResource<I[keyof I]>,
      ];
    }

    if (includeIterKey) {
      (includedResourcesIterative[includeIterKey] as ExtractResource<
        II[keyof II]
      >[]) = [
        ...(includedResourcesIterative[includeIterKey] as ExtractResource<
          II[keyof II]
        >[]),
        resource as unknown as ExtractResource<II[keyof II]>,
      ];
    }

    if (revIncludeKey) {
      (revIncludedResources[revIncludeKey] as ExtractResource<R[keyof R]>[]) = [
        ...(revIncludedResources[revIncludeKey] as ExtractResource<
          R[keyof R]
        >[]),
        resource as unknown as ExtractResource<R[keyof R]>,
      ];
    }

    if (revIncludeIterKey) {
      (revIncludedResourcesIterative[revIncludeIterKey] as ExtractResource<
        RI[keyof RI]
      >[]) = [
        ...(revIncludedResourcesIterative[revIncludeIterKey] as ExtractResource<
          RI[keyof RI]
        >[]),
        resource as unknown as ExtractResource<RI[keyof RI]>,
      ];
    }

    return false;
  });

  const bundleOrSingleResource = (
    params.isBundle ? originalResources : originalResources[0]
  ) as B extends true ? ExtractResource<T>[] : ExtractResource<T>;

  return {
    data: params.postProcessData
      ? params.postProcessData(bundleOrSingleResource)
      : bundleOrSingleResource,
    includedResources,
    includedResourcesIterative,
    revIncludedResources,
    revIncludedResourcesIterative,
  };
}

/**
 * Gets all query string params with the provided resource type specifier, filtering out params
 * that should never be set in the query string
 * @param resourceName The name of the FHIR resource
 * @param searchParams The current React Router URLSearchParams object
 * @returns a URLSearchParams object representing all `paramName[resourceType]` URL search params
 *   without the resource type specifier
 *
 * @remarks
 * We'll never set `_id`, `_include`, or `_revinclude` from the browser's search params as this has
 * the potential to cause super weird behavior and 500s from Medplum
 */
export function getResourceGuardedSearchParams(
  resourceType: ResourceType,
  searchParams: URLSearchParams,
): URLSearchParams {
  return new URLSearchParams(
    Object.fromEntries(
      [...searchParams.entries()]
        .filter(
          ([key]) =>
            key.includes(`[${resourceType}]`) &&
            !key.includes('_id') &&
            !key.includes('_include') &&
            !key.includes('_revinclude'),
        )
        .map(([key, value]) => [key.replace(`[${resourceType}]`, ''), value]),
    ),
  );
}

/**
 * Deeply merges two `UseFhirResourceNewParams` objects
 * @typeParam F A function from `@vannaconnect/fhir` that matches the resource you wish to query
 * @typeParam B A boolean that determines if the resource you wish to fetch should be a bundle
 * @typeParam I A map of inclusion keys to functions from `@vannaconnect/fhir`
 * @typeParam R A map of reverse inclusion keys to functions from `@vannaconnect/fhir`
 * @param defaultParams Hook parameters to be used by default
 * @param overrideParams Hook parameters used to override the default hook parameters
 * @returns an object representing a deeply merged clone of the two parameter objects
 */
export function mergeUseFhirResourceParams<
  T extends ResourceType,
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(
  // We want engineers to be able to provide default params for both bundles and single resources,
  // by omitting isBundle and setting the first param's B generic to true | false, we can do this
  // without anyone implementing the hooks needing to typecast
  defaultParams: Omit<
    UseFhirResourceNewParams<T, true | false, I, R, II, RI>,
    'isBundle'
  >,
  overrideParams: UseFhirResourceNewParams<T, B, I, R, II, RI>,
): UseFhirResourceNewParams<T, B, I, R, II, RI> {
  return deepMergeObject(defaultParams, overrideParams);
}

export function removeNullUndefinedValues<T extends object>(
  obj: T,
): NonNullable<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_key, value]) =>
        value !== null &&
        value !== undefined &&
        ((Array.isArray(value) && value.length > 0) || !Array.isArray(value)),
    ),
  ) as NonNullable<T>;
}

/**
 * A wrapper that creates a modified Tanstack Query retry function to ensure automatic refetching
 * of Medplum tokens on the first auth failure
 * @param queryClient A tanstack query `QueryClient`
 * @returns a function compatible with Tanstack Query's `retry` param
 */
export function retryWithTokenRefreshBuilder(queryClient: QueryClient) {
  // @ts-ignore We don't want retries in tests as it makes our error state tests take forever
  if (import.meta.env.MODE === 'test') {
    return false;
  }
  // We have to pass in the query client to this abstraction, thus immediately returning a func
  return (failureCount: number, error: Error): boolean => {
    if (error.message !== 'Unauthorized') {
      // If the error isn't authorization related, retry twice before showing an error message
      return failureCount <= 2;
    }
    if (failureCount < 2) {
      // If the request failed once due to an auth error, let's invalidate the query cache for
      // our tokens and then retry
      queryClient.invalidateQueries({ queryKey: [AUTH_TOKENS_ENDPOINT] });
      return true;
    }
    // If auth has failed more than once, show an error and prevent additional retried to avoid
    // auth refetch race conditions
    return false;
  };
}

/**
 * A modified Tanstack Query retryDelay function that ensures enough time for automatic refetching
 * of Medplum tokens on the first auth failure
 * @param failureCount The number of failures
 * @param error The error thrown by the query / mutation
 * @returns a number denoting the number of milliseconds between each retry
 */
export function retryDelayWithTokenRefresh(
  failureCount: number,
  error: Error,
): number {
  if (error.message !== 'Unauthorized') {
    // This is the default Tanstack Query retry behavior copied from their docs, we'll use
    // this for any failure that isn't auth related
    return Math.min(1000 * 2 ** failureCount, 30000);
  }
  // For all auth-related failures, let's make sure to give the app plenty of time to refresh
  // tokens and give it a 5 second delay between retries
  return 5000;
}
