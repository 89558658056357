import { useMutation as useTanstackMutation } from '@tanstack/react-query';

import { requestHandler } from '#utils/tanstackQuery';
import type { MutationParams, RequestParams } from '#utils/tanstackQuery';

import type { UseMutationResult } from './useMutation.types';

/**
 * A hook used to create typesafe requests that modify data in an API
 * @typeParam R Type of the response body returned by the mutation
 * @typeParam B Type of the request body sent alongside the mutation
 * @param url The URL you wish to fetch
 * @param reqParams Additional Fetch params to send with the request (optional)
 * @param mutationParams Additional Tanstack Query params to initialize the hook with (optional)
 * @returns an array with the first element being an async function to call the mutation and the
 *   second element being the data, error, and loading / idle states of the mutation
 *
 * @example
 * ```tsx
 * interface Response {
 *   foo: string;
 * }
 *
 * interface Response {
 *   bar: string;
 * }
 *
 * export function MyComponent() {
 *   const [mutate, { data, error, isIdle, isLoading }] = useMutation(
 *     'http://todolist.private/tasks',
 *     { method: 'post' },
 *     { onSuccess: () => {} }
 *   );
 *
 *   return <button onClick={() => mutate({ bar: 'baz' })}>Mutate</button>
 * }
 * ```
 */
export function useMutation<
  R extends object | object[],
  B extends object | object[],
>(
  baseUrl: string,
  reqParams: RequestParams = {},
  mutationParams: MutationParams<R, B> = {},
) {
  const mutationFn = (body: B) => {
    const { id } = body as { id?: string };
    const url = id ? `${baseUrl}/${id}` : baseUrl;
    return requestHandler<R>(url, { ...reqParams, body });
  };

  const { isPending, ...res } = useTanstackMutation<R, Error, B, unknown>({
    ...mutationParams,
    mutationFn,
  });

  return [
    res.mutateAsync,
    { ...res, data: res.data || null, isLoading: isPending },
  ] as unknown as UseMutationResult<R, B>;
}
