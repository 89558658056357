import type { ReactNode } from 'react';
import { Button as ReactAriaButton } from 'react-aria-components';
import type { ButtonProps as ReactAriaButtonProps } from 'react-aria-components';

import { tv } from '../../utils';
import { Icon } from '../Icon';
import type { IconName } from '../Icon';
import { Progress } from '../Progress';

export interface ButtonProps extends ReactAriaButtonProps {
  /** The content of the Button */
  children: ReactNode;
  /** Adds additional Tailwind classes to the Button container element */
  className?: string;
  /** The color theme of the Button */
  color?: 'destructive' | 'primary';
  /** Adds an icon to the left side of the Button's content */
  iconLeft?: IconName;
  /** Puts the button in a disabled state */
  isDisabled?: ReactAriaButtonProps['isDisabled'];
  /** Adds a loading indicator to the left side of the button, disabling interaction */
  isLoading?: ReactAriaButtonProps['isDisabled'];
  /** The callback function executed when the Button is clicked or tapped */
  onPress?: ReactAriaButtonProps['onPress'];
  /** The font size and padding of the Button */
  size?: 'sm' | 'md';
  /** The outline style of the Button */
  variant?: 'contained' | 'outlined' | 'text';
}

const buttonStyles = tv({
  slots: {
    base: 'font-default font-normal flex gap-x-1 items-center justify-center text-center transition-colors w-auto',
  },
  variants: {
    // Colors only use compound variants in this case, so these are left as empty strings
    color: {
      destructive: {
        base: '',
      },
      primary: {
        base: '',
      },
    },
    isDisabled: {
      true: {},
      false: {},
    },
    isLoading: {
      true: {},
      false: {},
    },
    size: {
      sm: {
        base: 'px-3 py-2 text-button2',
      },
      md: {
        base: 'px-4 py-2 text-button1',
      },
    },
    variant: {
      contained: {
        base: 'rounded-full',
      },
      outlined: {
        base: 'border border-solid rounded-full',
      },
      text: {
        base: ' active:outline-primary-3 focus-visible:outline-none active:outline-offset-0',
      },
    },
  },
  compoundVariants: [
    {
      color: 'primary',
      variant: 'contained',
      class: {
        base: 'bg-primary-3 hover:bg-primary-4 text-neutrals-0',
      },
    },
    {
      color: 'primary',
      variant: 'outlined',
      class: {
        base: 'border border-primary-3 hover:border-primary-4 border-solid text-primary-3 hover:text-primary-4',
      },
    },
    {
      color: 'primary',
      variant: 'text',
      class: {
        base: 'text-primary-3 hover:text-primary-4',
      },
    },
    {
      color: 'destructive',
      variant: 'contained',
      class: {
        base: 'bg-critical-3 hover:bg-critical-4 text-neutrals-0',
      },
    },
    {
      color: 'destructive',
      variant: 'outlined',
      class: {
        base: 'border border-critical-3 hover:border-critical-4 border-solid text-critical-3 hover:text-critical-4',
      },
    },
    {
      color: 'destructive',
      variant: 'text',
      class: {
        base: 'text-critical-3 hover:text-critical-4',
      },
    },
    {
      isDisabled: true,
      variant: 'contained',
      class: {
        base: 'bg-state-disabled hover:bg-state-disabled text-state-disabledText hover:text-state-disabledText',
      },
    },
    {
      isDisabled: true,
      variant: 'outlined',
      class: {
        base: 'border border-state-disabled hover:border-state-disabled text-state-disabledText hover:text-state-disabledText',
      },
    },
    {
      isDisabled: true,
      variant: 'text',
      class: {
        base: 'text-state-disabledText hover:text-state-disabledText',
      },
    },
    // Since we also set the button to disabled when loading, we need a compound variant to tell
    // the button to use the progress cursor when loading
    {
      isDisabled: true,
      isLoading: true,
      class: {
        base: 'cursor-progress',
      },
    },
    {
      isDisabled: true,
      isLoading: false,
      class: {
        base: 'cursor-not-allowed',
      },
    },
  ],
});

/**
 * Allows users to take actions with a single click or tap
 *
 * @example
 * ```tsx
 * <Button
 *   color="primary"
 *   onPress={() => alert('You pressed me!')
 *   size="sm"
 *   variant="contained"}
 * />
 * ```
 */
export function Button({
  children,
  className,
  color = 'primary',
  iconLeft,
  isDisabled = false,
  isLoading = false,
  size = 'md',
  variant = 'contained',
  ...reactAriaProps
}: ButtonProps) {
  const styles = buttonStyles({
    color,
    // Buttons should be put into a disabled state when loading
    isDisabled: isDisabled || isLoading,
    size,
    variant,
  });
  return (
    <ReactAriaButton
      aria-busy={isLoading}
      aria-live={isLoading ? 'polite' : undefined}
      className={styles.base({ className })}
      isDisabled={isDisabled || isLoading}
      {...reactAriaProps}
    >
      {iconLeft && !isLoading && <Icon name={iconLeft} />}
      {isLoading && <Progress size={size} />}
      {children}
    </ReactAriaButton>
  );
}
