import { Avatar, type AvatarProps } from '../Avatar';
import { Button } from '../Button';
import { Menu } from '../Menu';
import { Popover } from '../Popover';

export interface NavBarUserMenuProps {
  name: AvatarProps['name'];
}

export function NavBarUserMenu({ name }: NavBarUserMenuProps) {
  return (
    <Popover
      label="Logout"
      trigger={
        <Button variant="text" className="p-0">
          <Avatar size="md" name={name} />
        </Button>
      }
    >
      {() => (
        <Menu>
          <Menu.Item
            // TODO: Add logout functionality
            onPress={() => {
              window.localStorage.clear();
              window.location.href = '/';
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      )}
    </Popover>
  );
}
