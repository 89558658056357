export const ACTION_PLAN_QUESTIONNAIRE_TITLE = 'Action Plan';
export const CARE_TEAM_MEETING_QUESTIONNAIRE_TITLE = 'Care Team Meeting';
export const CLINICAL_SOAP_NOTE_QUESTIONNAIRE_TITLE = 'Clinical SOAP Note';
export const ENCOUNTER_NOTE_QUESTIONNAIRE_TITLE = 'Encounter Note';
export const ENROLLMENT_QUESTIONNAIRE_TITLE = 'Enrollment Form';
export const GENERAL_QUESTIONNAIRE_TITLE = 'General Communication Note';
export const INTAKE_SCREENER_QUESTIONNAIRE_TITLE = 'Intake Screener';
export const OUTREACH_QUESTIONNAIRE_TITLE = 'Outreach';
export const PSYCH_EVAL_QUESTIONNAIRE_TITLE = 'Psych Evaluation';
export const ADD_MEMBER_TITLE = 'Add Member';

// Note: These questionnaires are read-only and an artifact from the great AppSheet Migration of
// July 2024. Please never pull these in unless it's to read historical data
export const APPSHEET_ENCOUNTER_NOTE_QUESTIONNAIRE_TITLE =
  'Appsheet Encounter Note';
export const APPSHEET_OUTREACH_QUESTIONNAIRE_TITLE = 'Appsheet Outreach Note';

// TODO: This should probably eventually come from a backend so we don't have to modify code every
// single time product wants to add a new questionnaire
export const QUESTIONNAIRES_TO_FETCH = [
  ACTION_PLAN_QUESTIONNAIRE_TITLE,
  CARE_TEAM_MEETING_QUESTIONNAIRE_TITLE,
  CLINICAL_SOAP_NOTE_QUESTIONNAIRE_TITLE,
  ENCOUNTER_NOTE_QUESTIONNAIRE_TITLE,
  ENROLLMENT_QUESTIONNAIRE_TITLE,
  GENERAL_QUESTIONNAIRE_TITLE,
  INTAKE_SCREENER_QUESTIONNAIRE_TITLE,
  PSYCH_EVAL_QUESTIONNAIRE_TITLE,
  OUTREACH_QUESTIONNAIRE_TITLE,
];
