import { useAuthProfile } from '@vannaconnect/medplum';
import { Navbar } from '@vannaconnect/ui';
import { Suspense } from 'react';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom';
import type { NonIndexRouteObject } from 'react-router-dom';
import { ADD_MEMBER_TITLE } from '#members/constants/QUESTIONNAIRES.js';
import { useQuestionnaire } from '@vannaconnect/medplum';

//
// When creating modules, modify the below code
//

// Step 1: Import module routes here
import { dashboardRoutes } from './modules/dashboard/dashboard.routes';
import { membersRoutes } from './modules/members/members.routes';
import { populationHealthRoutes } from './modules/population-health/populationHealth.routes';
import { vannaPractitioner } from '@vannaconnect/fhir/v2';

// Step 2: Spread your imported routes into this object
const ROUTES: NonIndexRouteObject[] = [
  ...dashboardRoutes,
  ...membersRoutes,
  ...populationHealthRoutes,
];

// Step 3 (Optional): If your routes need an item in the top-level navbar, add them here
const NAVBAR_ROUTES = {
  // Note: Commented out but retained for potential future usage.
  // '/': 'Home',
  '/funnel?stage=engaged': 'Funnel',
  '/members?stage=engaged': 'Members',
  // Note: Commented out but retained for potential future usage.
  // '/population-health': 'Population Health',
};

//
// No need to modify anything else here, happy coding!
//

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return <div>Dang!</div>;
}

// Normally we adhere to a pretty strict "one component per file" rule, however for setting up
// React Router this pattern makes the most sense
function RootRoute() {
  const { data } = useAuthProfile();
  const { data: addMemberData } = useQuestionnaire({
    isBundle: true,
    defaultBundleParams: {
      filter: {
        title: [ADD_MEMBER_TITLE],
      },
    },
  });
  return (
    <>
      <Navbar
        routes={NAVBAR_ROUTES}
        name={vannaPractitioner.name.getAvatarName(data!)}
        addMemberQuestionnaireID={addMemberData?.[0].id!}
      />
      <Outlet />
    </>
  );
}

/**
 * Handles all routing for your app. Module routes should be directly imported into this component
 */
export function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        // TODO: Create skeleton of <Page /> to use for loading state
        <Suspense>
          <RootRoute />
        </Suspense>
      ),
      children: ROUTES,
      errorElement: <ErrorBoundary />,
    },
  ]);
  return <RouterProvider router={router} />;
}
