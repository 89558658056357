import { jwtDecode } from 'jwt-decode';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from '../constants/auth';

export interface MedplumTokens {
  accessToken: string;
  refreshToken: string;
}

/** A successful response from the medplum oauth2/token endpoint */
export interface MedplumOauth2TokenRes {
  access_token: string;
  refresh_token: string;
}

/**
 * Creates the necessary request headers to send authenticated Medplum requests
 * @param accessToken A Medplum access token
 * @returns a partial HeadersInit object with an "Authorization" key / value
 */
export function createAuthHeaders(accessToken: string): HeadersInit {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

/**
 * Creates a sign in URL for external OAuth2 applications to exchange tokens with Medplum
 * @param externalAuthorizeUrl The OAuth2 authorize endpoint of the external provider
 * @param externalClientId The OAuth2 client ID of the external provider
 * @param medplumBaseUrl The base URL of a Medplum instance
 * @param medplumClientId The Client ID of the Medplum client configured for the external provider
 * @returns An encoded URI that the user can navigate to for initiating sign in
 *
 * @remarks
 * Both the `externalAuthorizeUrl` and the `externalClientId` **must** be configured in the Medplum
 * client that's associated with `medplumClientId`
 */
export function createExternalSignInUrl(
  externalAuthorizeUrl: string,
  externalClientId: string,
  medplumBaseUrl: string,
  medplumClientId: string,
): string {
  // This object is used by external auth providers when they redirect back to the Medplum API
  // after the user authenticates. It provides critical information to Medplum about which app is
  // attempting to authenticate so that we can link our OAuth token to a Medplum instance
  const medplumState = {
    clientId: medplumClientId,
    // Currently this is set to always redirect back to the page that sent the login request. I
    // don't see a world where this changes, however if we ever need to redirect to a different
    // origin post-auth, this is where that change would be made
    redirectUri: `${window.location.origin}`,
  };

  const queryParams = new URLSearchParams({
    client_id: externalClientId,
    // It's important to note that when authenticating with a 3rd party, we need to redirect to the
    // Medplum API itself. From there, Medplum generates an OAuth access token and then redirects
    // the user back to the URL passed into medplumState.redirectUri. This means that when we
    // configure a 3rd party OAuth provider, its redirect URI should **always** be the Medplum API
    redirect_uri: `${medplumBaseUrl}/auth/external`,
    response_type: 'code',
    scope: 'openid profile email',
    state: JSON.stringify(medplumState),
  });

  const externalSignInUrl = `${externalAuthorizeUrl}?${queryParams.toString()}`;

  return externalSignInUrl;
}

/**
 * Decodes a JWT and determines if it's expired
 * @param token A single JSON Web Token
 *
 * @example
 * ```typescript
 * if (isTokenExpired()) {
 *   throw new Error('Token is Expired');
 * }
 * ```
 */
export function isTokenExpired(token: string): boolean {
  try {
    const tokenExpTimestamp = jwtDecode(token).exp ?? 0;
    const currentTimestamp = Date.now() / 1000;

    return tokenExpTimestamp < currentTimestamp;
  } catch (err) {
    console.error('Provided token is invalid, unable to check expiration', err);
    return true;
  }
}

/**
 * Clears the user's local storage and navigates the user to the root of the app
 * @param errorMessage An optional error message to be displayed on the login page. This is
 *   useful for cases such as expired tokens where the user is forced to logout
 *
 * @example
 * ```typescript
 * logOut("You've been logged out due to a system error, please try again");
 * ```
 */
export async function logOut(errorMessage?: string) {
  window.localStorage.clear();
  window.location.href = errorMessage?.length
    ? `/?errorMessage=${errorMessage}`
    : '/';
}

/**
 * Retrieves Medplum access token and refresh token from local storage
 * @returns an object containing a Medplum access token and refresh token if both exist, otherwise
 *   returns null
 */
export function retrieveTokensFromStorage(): MedplumTokens | null {
  const accessToken = window.localStorage.getItem(
    ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  );
  const refreshToken = window.localStorage.getItem(
    REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  );

  if (!accessToken || !refreshToken) {
    return null;
  }

  return { accessToken, refreshToken };
}

/**
 * Sets Medplum access token and refresh token in local storage
 * @param tokens An object containing a Medplum access token and refresh token
 */
export function setTokensInStorage(tokens: MedplumTokens) {
  window.localStorage.setItem(
    ACCESS_TOKEN_LOCAL_STORAGE_KEY,
    tokens.accessToken,
  );
  window.localStorage.setItem(
    REFRESH_TOKEN_LOCAL_STORAGE_KEY,
    tokens.refreshToken,
  );
}
