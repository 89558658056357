import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { config, validateConfig } from './config';

// Only imports that need to be resolved before render (such as Tailwind and i18n) go here
import '@vannaconnect/ui/ui.css';
import './i18n';

validateConfig();

// Note: Even if a DSN is provided, sentry will only run when app is built with the build:staging
// or build:production commands, and never runs when built with build or dev
if (config.VITE_SENTRY_DSN?.length && import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: config.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'app.staging.vannahealth.io',
      'app.vannahealth.io',
    ],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

createRoot(document.getElementById('root')!).render(<App />);
