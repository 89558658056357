import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { tv } from '../../utils';
import { VannaLogo } from '../VannaLogo';
import { Icon } from '../Icon';
import type { AvatarProps } from '../Avatar';
import { NavBarUserMenu } from './NavbarUserMenu';
import { useDrawerParams } from '#hooks/useDrawerParams/useDrawerParams.js';

export interface NavbarProps {
  /** Adds additional components such as an avatar or action button to the right side of the nav */
  additionalContent?: ReactNode;
  /** Adds additional Tailwind classes to the Navbar container element */
  className?: string;
  /** The text that a screen reader will use to describe the Navbar to users */
  label?: string;
  /** The menu items to be displayed in the Navbar */
  routes: Record<string, string>;
  /** Name for Avatar */
  name?: AvatarProps['name'];
  /** Add Member Questionnaire ID */
  addMemberQuestionnaireID: string;
}

const navbarStyles = tv({
  slots: {
    // Note: In the Figma the border color for the base element didn't have a design system
    // variable, so I'm using neutrals-n3 as a temporary replacement as it's the closest color.
    // TODO: Ensure this is the correct color once StoneTable finalizes the navbar
    base: 'bg-neutrals-0 border-b border-solid border-neutrals-3 flex font-default font-medium h-16 items-center px-6',
    logo: 'block h-6 pr-3',
    menu: 'flex grow h-full',
    // menuItemWrapper: 'block',
    menuItem:
      'block border-b-4 border-neutrals-6/0 hover:border-neutrals-6/100 border-solid mx-3 pb-4 pt-5 transition-all text-neutrals-6',
  },
  variants: {
    isActive: {
      true: {
        menuItem: 'border-primary-3 hover:border-primary-4 text-neutrals-8',
      },
    },
  },
});

/**
 * The primary global navigation for Vanna Connect apps
 *
 * @example
 * ```tsx
 * <Navbar
 *  label="Vanna Health Member Portal"
 *  routes={{
 *    '/': 'Home',
 *    '/visits': 'My Visits',
 *  }}
 *  name={{
 *    firstName: 'Claire',
 *    lastName: 'Waldorferous'
 *  }}
 * />
 * ```
 */
export function Navbar({
  additionalContent = null,
  className,
  label = 'Vanna Connect',
  routes,
  name,
  addMemberQuestionnaireID,
}: NavbarProps) {
  const styles = navbarStyles();

  const [openDrawer] = useDrawerParams<{
    questionnaire: {
      questionnaireId: string;
    };
  }>();

  // Accessibility is hard, especially when you have to do it yourself. Unfortunately, react-aria
  // doesn't provide a headless navbar component, but thankfully the W3C has some really amazing
  // docs and examples on building accessible components. All of the aria and role attributes in
  // this component are based directly off of the W3C's APG patterns collection which can be found
  // at https://www.w3.org/WAI/ARIA/apg/patterns/menubar/examples/menubar-navigation
  return (
    <nav aria-label={label} className={styles.base({ className })}>
      <VannaLogo className={styles.logo()} variant="icon" />
      <ul aria-label={label} className={styles.menu()} role="menubar">
        {Object.entries(routes).map(([path, label]) => (
          <li key={path} role="none">
            <NavLink
              // react-router gives us a function to define classNames for NavLink instead of a
              // string. This allows us to add styles for active items easily, and it works really
              // well with tailwind-variants
              className={({ isActive }) => styles.menuItem({ isActive })}
              end={false}
              role="menuitem"
              to={path}
            >
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
      {additionalContent}
      <a
        onClick={() => {
          openDrawer('questionnaire', {
            questionnaireId: addMemberQuestionnaireID,
          });
        }}
        className="mx-6 mr-4 ml-2 cursor-pointer flex"
      >
        <Icon name="user-plus" className="text-neutrals-7 w-6" />
      </a>
      <Navbar.UserMenu name={name} />
    </nav>
  );
}

Navbar.UserMenu = NavBarUserMenu;
