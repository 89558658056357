import { useFhirResourceNew } from '#hooks/useFhirResourceNew';
import type {
  FhirResourceInclusion,
  UseFhirResourceNewParams,
} from '#hooks/useFhirResourceNew';

export function useQuestionnaire<
  B extends boolean,
  I extends FhirResourceInclusion,
  R extends FhirResourceInclusion,
  II extends FhirResourceInclusion,
  RI extends FhirResourceInclusion,
>(params: UseFhirResourceNewParams<'Questionnaire', B, I, R, II, RI>) {
  return useFhirResourceNew('Questionnaire', params);
}
