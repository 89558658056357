import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

//
// When creating modules, modify the below code
//

// Step 1: en-US translations ONLY from your module here using the module's name as the identifier
import dashboard from './modules/dashboard/i18n/en-US.json';
import members from './modules/members/i18n/en-US.json';
import populationHealth from './modules/population-health/i18n/en-US.json';

// Step 2: Add your imported translations to this object
interface Translations {
  dashboard: typeof dashboard;
  members: typeof members;
  'population-health': typeof populationHealth;
}

//
// No need to modify anything else here, happy coding!
//

i18n
  .use(
    // Translations are code split and lazy loaded only when needed. This means that if I have
    // modules foo, bar, and baz, and I load module foo, translations for bar and baz won't load
    // until their individual modules are loaded. This has the potential to shave a ton of size
    // off of the final bundle. The same thing is done with languages! If I load the page with my
    // browser's language set to English, it won't load Spanish or French translations until I
    // switch my language and reload the page
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./modules/${namespace}/i18n/${language}.json`),
    ),
  )
  .use(initReactI18next)
  .init({ lng: 'en-US' });

declare module 'i18next' {
  interface CustomTypeOptions {
    resources: Translations;
  }
}
