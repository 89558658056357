import type { Practitioner } from '@medplum/fhirtypes';
import { useQuery } from '@vannaconnect/ui';

import { AUTH_PROFILE_ENDPOINT } from '../../constants/auth';
import { createAuthHeaders } from '../../utils/auth';

import { useAuthToken } from '../useAuthToken';
import { useMedplumClient } from '../useMedplumClient';

export interface MedplumAuthProfileResponse {
  profile: Practitioner;
}

export function useAuthProfile() {
  const { baseUrl } = useMedplumClient();
  const { accessToken } = useAuthToken();

  const { data, ...query } = useQuery<MedplumAuthProfileResponse>(
    `${baseUrl}/${AUTH_PROFILE_ENDPOINT}`,
    [AUTH_PROFILE_ENDPOINT],
    {
      headers: createAuthHeaders(accessToken!),
    },
  );

  return { ...query, data: data ? data.profile : data };
}
