import { AuthGate, MedplumClientProvider } from '@vannaconnect/medplum';
import { Motion, QueryProvider, Snackbar } from '@vannaconnect/ui';
import { StrictMode } from 'react';

import { config } from './config';
import { Router } from './Router';

/**
 * Renders the main app router and any global providers, nothing more
 */
export function App() {
  return (
    <StrictMode>
      <Motion>
        <QueryProvider>
          <MedplumClientProvider
            baseUrl={config.VITE_MEDPLUM_BASE_URL}
            clientId={config.VITE_MEDPLUM_CLIENT_ID}
          >
            <AuthGate
              oktaAuthorizeUrl={config.VITE_OKTA_AUTHORIZE_URL}
              oktaClientId={config.VITE_OKTA_CLIENT_ID}
            >
              <Router />
              <Snackbar />
            </AuthGate>
          </MedplumClientProvider>
        </QueryProvider>
      </Motion>
    </StrictMode>
  );
}
