import { createContext } from 'react';

export interface MedplumClientContextParams {
  /** The base URL of the Medplum instance you wish to connect to */
  baseUrl: string;
  /** The client ID of the Medplum instance you wish to connect to */
  clientId: string;
}

export const MedplumClientContext = createContext<MedplumClientContextParams>({
  baseUrl: 'https://api.medplum.private',
  clientId: 'no-client-id-provided',
});
