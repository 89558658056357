import { Navigate, type RouteObject } from 'react-router-dom';

// Note: Commented out but retained for potential future usage.
// import { RootPage } from './pages/Root';

export const dashboardRoutes = [
  {
    path: '/',
    // Note: Commented out but retained for potential future usage.
    // element: <RootPage />,
    element: <Navigate to="members" />,
  },
] satisfies RouteObject[];
