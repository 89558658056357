import type { HumanName } from '@medplum/fhirtypes';

import { practitioner as FhirPaths } from '#fhirpath';
import { constructFirstResultGetter } from '../../utils/fhirpath';

export const getName = constructFirstResultGetter<HumanName>(
  FhirPaths.name.collection,
);

export const getProfilePictureUrl = constructFirstResultGetter<string>(
  FhirPaths.profilePictureUrl.collection,
);

export const validateName = constructFirstResultGetter<HumanName>(
  FhirPaths.name.valid,
);
