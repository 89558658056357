import type { RouteObject } from 'react-router-dom';

export const membersRoutes = [
  {
    path: '/funnel',
    lazy: async () => {
      const { FunnelPage } = await import('./pages/Funnel');
      return { Component: FunnelPage };
    },
  },
  {
    path: '/members',
    lazy: async () => {
      const { MembersPage } = await import('./pages/Members');
      return { Component: MembersPage };
    },
  },
  {
    path: '/members/:memberId',
    lazy: async () => {
      const { MemberProfileLayout } = await import('./layouts/MemberProfile');
      return { Component: MemberProfileLayout };
    },
    children: [
      {
        path: '/members/:memberId',
        lazy: async () => {
          const { MemberProfileOverviewPage } = await import(
            './pages/MemberProfileOverview'
          );
          return { Component: MemberProfileOverviewPage };
        },
      },
      {
        path: 'assessments',
        lazy: async () => {
          const { MemberProfileAssessmentsPage } = await import(
            './pages/MemberProfileAssessments'
          );
          return { Component: MemberProfileAssessmentsPage };
        },
      },
      {
        path: 'care-review',
        lazy: async () => {
          const { MemberProfileCareReviewPage } = await import(
            './pages/MemberProfileCareReview'
          );
          return { Component: MemberProfileCareReviewPage };
        },
      },
      {
        path: 'clinical',
        lazy: async () => {
          const { MemberProfileClinicalPage } = await import(
            './pages/MemberProfileClinical'
          );
          return { Component: MemberProfileClinicalPage };
        },
      },
      {
        path: 'communication',
        lazy: async () => {
          const { MemberProfileCommunicationPage } = await import(
            './pages/MemberProfileCommunication'
          );
          return { Component: MemberProfileCommunicationPage };
        },
      },
      {
        path: 'contact',
        lazy: async () => {
          const { MemberProfileContactPage } = await import(
            './pages/MemberProfileContact'
          );
          return { Component: MemberProfileContactPage };
        },
      },
      {
        path: 'encounters',
        lazy: async () => {
          const { MemberProfileEncountersPage } = await import(
            './pages/MemberProfileEncounters'
          );
          return { Component: MemberProfileEncountersPage };
        },
      },
    ],
  },
] satisfies RouteObject[];
