import { LazyMotion } from 'framer-motion';
import type { ReactNode } from 'react';

async function loadFeatures() {
  const res = await import('./features');
  return res.default;
}

export interface MotionProps {
  /** The content to provide motion features to */
  children: ReactNode;
}

/**
 * A lazy-loaded wrapper around Framer Motion designed to reduce bundle size for components with
 * complex animations
 *
 * @example
 * ```tsx
 * import { Motion } from '@vannaconnect/ui';
 * import { m } from 'framer-motion';
 *
 * <Motion>
 *  <m.div initial={{ opacity: 0 }} />
 * </Motion>
 * ```
 */
export function Motion({ children }: MotionProps) {
  return <LazyMotion features={loadFeatures}>{children}</LazyMotion>;
}
