import { merge } from 'lodash-es';

/**
 * Makes all properties in `T` and any nested objects optional
 * @typeParam T Typing representing an object
 */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/**
 * Deeply merges two objects with Lodash!
 * @typeParam T Typing representing the returned merged object
 * @typeParam L Optional Typing representing left object, defaults to `DeepPartial<T>`
 * @typeParam R Optional Typing representing right object, defaults to `DeepPartial<T>`
 * @param left An object that may have its values overwritten by the right object
 * @param right An object that may overwrite the left object's values
 * @returns A single object of type `T`, with the right object overwriting any parameters on the
 *   left object with the same name
 *
 * @example
 * ```ts
 * deepMergeObject({ foo: { bar: 'baz' } }, { foo: { bar: 'qux' } }>);
 * ```
 *
 * @remarks
 * Lodash's implementation of merge is mostly untyped, this function exists as a wrapper around
 * Lodash to finer control over typings
 */
export function deepMergeObject<
  T extends object = object,
  L extends object = DeepPartial<T>,
  R extends object = DeepPartial<T>,
>(left: L, right: R): T {
  return merge(left, right) as unknown as T;
}
